import { useDispatch } from 'react-redux';
import { navigate } from '@reach/router';
import { useToast } from 'libraryV2/ui/use-toast';

const useSSOAuth = () => {
  let { toast } = useToast();
  const dispatch = useDispatch();

  const SSOProviderLogin = async (payload: {
    providerName: string;
    invitationToken: string | null;
  }) => {
    let response = await dispatch.auth.SSOProviderLogin(payload);
    return response;
  };

  const getAuthToken = async (payload: {
    code: string;
    state?: string | null;
    session_state: string;
  }) => {
    let response = await dispatch.auth.getAuthToken(payload);
    if (response?.access_token) {
      dispatch.auth.initLogin({
        access: response.access_token,
        refresh: response.refresh_token,
      });
      dispatch.auth.updateForceLogout(false);
      dispatch.auth.updateInfo({
        access: response.access_token,
        refresh: response.refresh_token,
      });
      navigate('/dashboard');
    } else {
      toast({
        title: `API failed to provide account information.`,
        description: 'Please try again or contact support',
        duration: 30,
      });
      navigate('/');
    }
  };

  return { SSOProviderLogin, getAuthToken };
};

export default useSSOAuth;
