import { Button } from 'libraryV2/ui/button';
import { Separator } from 'libraryV2/ui/separator';
import { Form } from 'libraryV2/ui/form';
import {
  CustomAgentToolType,
  ICustomAgentFormSteps,
  WebsiteToolData,
} from 'pages/raven/interface';
import { FC } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { customAgentformSchema, CustomAgentToolFormData } from './toolsSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { getDefaultToolData } from 'pages/raven/utils/utility';

import { AddNewToolButton } from './AddNewToolButton';
import { CustomAgentApiTool } from './apiTool/CustomAgentApiTool';
import { DocumentTool } from './documentTool/DocumentTool';
import { WebsiteTool } from './websiteTool/WebsiteTool';
import { ScrollArea } from 'libraryV2/ui/scroll-area';
import { useCustomAgentTools } from 'pages/raven/hooks/useCustomAgentTools';
import { toast } from 'libraryV2/ui/use-toast';

interface ToolsProps {
  step: ICustomAgentFormSteps;
  onNextStep: () => void;
  onPreviousStep: () => void;
}

export const Tools: FC<ToolsProps> = ({ onNextStep, onPreviousStep }) => {
  const {
    defaultAgentConfig,
    customAgentId,
    customAgentToolFormData,
    updateAgentFormToolData,
    createMultipleWebsiteTool,
  } = useCustomAgentTools();

  const form = useForm<CustomAgentToolFormData>({
    resolver: zodResolver(customAgentformSchema),
    mode: 'onChange',
    defaultValues: async () => ({
      tools: customAgentToolFormData,
    }),
  });

  const toolsFormFieldArray = useFieldArray({
    control: form.control,
    name: 'tools',
    keyName: '_id', // DEPRECATED: it will be deprecated in next major version, but works fine for now
  });

  const renderSingleTools = (tool: any, index: number) => {
    const toolType: CustomAgentToolType = tool?.type || 'api';
    if (toolType === 'api') {
      return (
        <CustomAgentApiTool
          key={tool?._id + index}
          toolData={tool}
          index={index}
          fieldArrayInstance={toolsFormFieldArray}
        />
      );
    } else if (toolType === 'document') {
      return (
        <DocumentTool
          key={tool?._id + index}
          tooldata={tool}
          index={index}
          fieldArrayInstance={toolsFormFieldArray}
        />
      );
    }
    return (
      <WebsiteTool
        key={tool?._id + index}
        toolData={tool}
        index={index}
        fieldArrayInstance={toolsFormFieldArray}
      />
    );
  };

  const onSubmit = async (data: CustomAgentToolFormData) => {
    await createMultipleWebsiteTool(data.tools as unknown as WebsiteToolData[]);
    updateAgentFormToolData(data.tools as unknown as WebsiteToolData[]);
    onNextStep();
    toast({
      toastType: 'success',
      title: 'Saved',
      description: 'Tools data has been saved successfully',
    });
    // Handle form submission
  };

  const handleSaveDraft = async () => {
    const draftData = form.getValues();
    const result = await createMultipleWebsiteTool(
      draftData?.tools as unknown as WebsiteToolData[]
    );
    if (result.some((r) => r.status === 'rejected')) {
      toast({
        toastType: 'destructive',
        title: 'Error',
        description: 'Something went wrong while saving the draft',
      });
      return;
    }
    updateAgentFormToolData(draftData?.tools as unknown as WebsiteToolData[]);
    toast({
      toastType: 'success',
      title: 'Saved as draft',
      description: 'Tools data has been saved as draft',
    });
  };

  const handleAddNewTool = (toolType: CustomAgentToolType, toolId: string) => {
    const newTool = getDefaultToolData({
      toolType,
      toolId,
      defaultConfig: defaultAgentConfig,
    }) as {
      type: typeof toolType;
      id: string;
      isOpen: boolean;
      data: any;
    };
    toolsFormFieldArray.append(newTool);
  };

  return (
    <div
      data-testid='raven-tools-section'
      className='flex flex-col h-[calc(100vh-56px)] w-full pt-6 overflow-y-auto'
    >
      <ScrollArea>
        <div className='flex flex-col flex-1 mx-auto max-w-2xl gap-6 min-h-0'>
          <div className='space-y-1.5'>
            <h1 className='font-bold flex text-xl leading-6'>Tools</h1>
            <p className='text-sm font-medium text-textSecondary'>
              Provide the necessary resources like APIs, documents, and links to
              enhance your custom agent's capabilities.
            </p>
          </div>
          <Separator />
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className='w-full'
              onKeyDown={(e) => (e.key === 'Enter' ? e.preventDefault() : e)}
            >
              <div className='flex flex-col w-full gap-4'>
                {toolsFormFieldArray.fields.map((singletool, index) =>
                  renderSingleTools(singletool, index)
                )}
                <AddNewToolButton
                  customAgentId={customAgentId}
                  onToolSelect={handleAddNewTool}
                />
                <div className='flex justify-between w-full'>
                  <Button
                    type='button'
                    variant='ghost'
                    className='bg-background-container'
                    onClick={onPreviousStep}
                  >
                    Back
                  </Button>
                  <div className='flex items-center gap-2'>
                    <Button
                      type='button'
                      onClick={handleSaveDraft}
                      variant={'outline'}
                    >
                      Save as Draft
                    </Button>
                    <Button
                      // disabled={!!Object.keys(form.formState.errors).length}
                      type='submit'
                    >
                      Save & Continue
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </Form>
        </div>
      </ScrollArea>
    </div>
  );
};
