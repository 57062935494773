import { Button } from '../../../libraryV2/ui/button';
import useSSOAuth from './useSSOAuth';

type SSOLoginProps = {
  className?: string;
  invitationToken: string;
};

const GoogleIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 48 48'
    className='w-5 h-5'
  >
    <path
      fill='#4285F4'
      d='M24 9.5c3.15 0 5.65 1.35 7.35 2.5l5.5-5.5C33.9 3.5 29.3 1.5 24 1.5 14.8 1.5 7.3 7.55 4.6 15.4l6.5 5.05C12.6 14.3 17.8 9.5 24 9.5z'
    />
    <path
      fill='#34A853'
      d='M46.5 24.5c0-1.65-.15-3.15-.45-4.65H24v9.3h12.7c-.6 3-2.4 5.55-5.1 7.25l6.5 5.05c3.8-3.5 6.4-8.65 6.4-16.95z'
    />
    <path
      fill='#FBBC05'
      d='M11.1 28.5c-.75-2.25-.75-4.75 0-7l-6.5-5.05c-2.25 4.5-2.25 9.85 0 14.35l6.5-5.05z'
    />
    <path
      fill='#EA4335'
      d='M24 46.5c5.3 0 9.9-1.75 13.2-4.75l-6.5-5.05c-1.8 1.2-4.05 1.95-6.7 1.95-6.2 0-11.4-4.8-12.9-11.3l-6.5 5.05C7.3 40.45 14.8 46.5 24 46.5z'
    />
  </svg>
);

const MicrosoftIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 48 48'
    className='w-5 h-5'
  >
    <path fill='#F25022' d='M6 6h17v17H6z' />
    <path fill='#7FBA00' d='M25 6h17v17H25z' />
    <path fill='#00A4EF' d='M6 25h17v17H6z' />
    <path fill='#FFB900' d='M25 25h17v17H25z' />
  </svg>
);

const SSOLogin: React.FC<SSOLoginProps> = ({ className, invitationToken }) => {
  let { SSOProviderLogin } = useSSOAuth();

  const handleSSOLogin = async (provider: string) => {
    let response = await SSOProviderLogin({
      providerName: provider,
      invitationToken: invitationToken || null,
    });
    if (response) {
      // If the response contains a link, redirect to it in the same window
      const link = response.auth_url || null; // Assuming the link is at index 0
      if (link) {
        window.location.href = link; // Redirect to the link
      }
    }
  };

  return (
    <div className={`flex flex-col gap-3 ${className}`}>
      <Button
        variant='outline'
        className='flex items-center gap-2'
        onClick={() => handleSSOLogin('google')}
      >
        <GoogleIcon />
        Continue with Google
      </Button>
      <Button
        variant='outline'
        className='flex items-center gap-2'
        onClick={() => handleSSOLogin('oidc')}
      >
        <MicrosoftIcon />
        Continue with Microsoft Account
      </Button>
    </div>
  );
};

export default SSOLogin;
