import axios from 'axios';
import {
  ICopilotButtonBlock,
  ICopilotConversation,
  ICopilotConversationBlock,
} from 'pages/inbox/inboxInterface';
import { parseBasicMarkdown } from 'pages/inbox/utils/functions';
import { FC } from 'react';
interface ActionBlockProps {
  setLoading: (val: boolean) => void;
  setCopilotConversationData: React.Dispatch<
    React.SetStateAction<ICopilotConversation[]>
  >;
  conversation: ICopilotConversationBlock;
  blockIndex: number;
}

export const ActionBlock: FC<ActionBlockProps> = ({
  conversation,
  blockIndex,
  setLoading,
  setCopilotConversationData,
}) => {
  const handleClick = async (param: ICopilotButtonBlock) => {
    try {
      const newConversation: ICopilotConversation = {
        id: '',
        type: 'quick_reply',
        title: param.title,
        conversation_id: 0,
      };
      setCopilotConversationData((prev) => [...prev, newConversation]);
      setLoading(true);
      let response;

      const axiosInstance = !param?.headers
        ? axios
        : axios.create({
            headers: {
              ...param.headers,
            },
          });

      if (param.method === 'GET') {
        response = await axios.get(param.url, { params: param.payload });
      } else if (param.method === 'POST') {
        response = await axiosInstance.post(param.url, { ...param.payload });
      } else if (param.method === 'PUT') {
        response = await axios.put(param.url, param.payload);
      } else if (param.method === 'DELETE') {
        response = await axios.delete(param.url, { data: param.payload });
      } else {
        throw new Error('Unsupported HTTP method');
      }
      setLoading(false);
      console.log(response.data);
    } catch (err) {
      setLoading(false);
      console.log('Error:', err);
    }
  };
  const MarkdownRenderer = (text: string) => {
    const renderMarkdown = (markdown: string) => {
      return { __html: parseBasicMarkdown(markdown) };
    };

    return <div dangerouslySetInnerHTML={renderMarkdown(text)} />;
  };
  return (
    <div className='block w-full'>
      <div
        className={`mb-1  inline-flex flex-col border p-2  rounded-tr-xl rounded-br-xl rounded-bl-xl ${
          blockIndex === 0 ? 'rounded-tl-sm' : 'rounded-tl-xl'
        } `}
      >
        {MarkdownRenderer(conversation.title)}
        <div className='mt-1 flex gap-1 flex-wrap'>
          {conversation?.buttons?.map(
            (button: ICopilotButtonBlock, index: number) => (
              <div
                key={index}
                onClick={() => handleClick(button)}
                className='inline-block border border-border-light rounded-lg py-1 px-2 cursor-pointer hover:bg-background-hover'
              >
                {button.title}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};
