import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguageKey } from 'utilities/utils';

const TranslationContext = createContext<any>(null);
const DEFAULT_LANGUAGE = 'english';

const TranslationProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch<any>();
  const dbLanguage = useSelector(
    (state: any) => state.auth?.dashboard_language || null
  );
  const activeNavId = useSelector((state: any) => state.dashboard?.navActiveId);

  const initialLanguageRef = useRef<string | null>(null);

  const getInitialLanguage = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlParamValue = urlParams.get('lang');

    // Process language sources in priority order
    const languageSources = [
      urlParamValue?.trim().toLowerCase(),
      dbLanguage?.trim().toLowerCase(),
      localStorage.getItem('language')?.trim().toLowerCase(),
      DEFAULT_LANGUAGE,
    ];

    const validLanguage =
      languageSources.find((lang) => lang && getLanguageKey(lang)) ||
      DEFAULT_LANGUAGE; // Find the first valid language

    const initialLanguage = getLanguageKey(validLanguage);
    return initialLanguage;
  };

  const [dashboardLanguage, setDashboardLanguage] = useState(
    getInitialLanguage()
  );

  useEffect(() => {
    initialLanguageRef.current = dashboardLanguage;
  }, [dashboardLanguage]);

  const isRtlLanguage = ['arabic'].includes(dashboardLanguage);

  const updateLanguage = useCallback(
    async (newLanguage: string) => {
      if (!newLanguage) return; // Guard clause to prevent empty updates
      const sanitizedLanguage = getLanguageKey(
        newLanguage.trim().toLowerCase()
      );

      // Avoid unnecessary updates if the language hasn't changed
      if (!sanitizedLanguage || sanitizedLanguage === dashboardLanguage) return;

      try {
        await dispatch.auth.updateStateData({
          key: 'dashboard_language',
          value: sanitizedLanguage,
        });

        setDashboardLanguage(sanitizedLanguage);
        localStorage.setItem('language', sanitizedLanguage);
      } catch (error) {
        console.error('Failed to update language:', error);
      }
    },
    [dispatch, dashboardLanguage]
  );

  useEffect(() => {
    const direction = dashboardLanguage === 'arabic' ? 'rtl' : 'ltr';
    document.body.setAttribute('dir', direction);

    return () => {
      document.body.removeAttribute('dir');
    };
  }, [dashboardLanguage]);

  useEffect(() => {
    const syncLanguage = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const urlParamValue = searchParams.get('lang');
      let newLanguage: string | null = null;

      if (urlParamValue) {
        newLanguage = getLanguageKey(urlParamValue.trim().toLowerCase());
      } else if (dbLanguage) {
        newLanguage = getLanguageKey(dbLanguage.trim().toLowerCase());
      } else {
        return; // Early exit if no new language is available
      }

      if (newLanguage && newLanguage !== dashboardLanguage) {
        try {
          await updateLanguage(newLanguage);
        } catch (error) {
          console.error('Error syncing language:', error);
        }
      }
    };

    syncLanguage();
  }, [dashboardLanguage, dbLanguage, updateLanguage]);

  return (
    <TranslationContext.Provider
      value={{
        dashboardLanguage,
        updateLanguage,
        isRtlLanguage,
        activeNavId,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslationContext = () => useContext(TranslationContext);
export default TranslationProvider;
