import { FC, memo, useState } from 'react';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'libraryV2/ui/dialog';
import { Loader2, Trash2 } from 'lucide-react';
import { Button } from 'libraryV2/ui/button';
import { ICrawledWebsitePage, WebsiteToolData } from 'pages/raven/interface';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useCustomAgentTools } from 'pages/raven/hooks/useCustomAgentTools';
interface DeleteTrainedPageModalProps {
  toolData: ICrawledWebsitePage;
}

export const DeleteTrainedPageModal1: FC<DeleteTrainedPageModalProps> = ({
  toolData,
}) => {
  const { updateAgentFormToolData } = useCustomAgentTools();
  const dispatch = useDispatch();
  const formContext = useFormContext();

  const [open, setOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const handleDeleteTrainedWebPage = async () => {
    setIsDeleting(true);
    const allFormTools: WebsiteToolData[] = formContext.getValues().tools ?? [];
    const toolIndex = allFormTools?.findIndex(
      (t) => t?.id === toolData?.tool_id
    );
    const currentWebsiteToolData = allFormTools[toolIndex] ?? {};

    try {
      await dispatch.raven.deleteSingleAgentEmbedData({
        pointId: toolData.id,
        toolId: toolData.tool_id,
      });

      formContext.setValue(
        `tools.${toolIndex}.data.pages`,
        currentWebsiteToolData.data.pages.filter(
          (page: ICrawledWebsitePage) => page?.id !== toolData?.id
        ),
        {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        }
      );
      // now update the form redux state
      updateAgentFormToolData(formContext.getValues().tools);
      setOpen(false);
      setIsDeleting(false);
    } catch (error) {}
    // now update form state
  };
  if (!toolData?.id) {
    return null;
  }
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className='flex items-center justify-center'>
        <Trash2 className='w-4 h-4 text-red-500' strokeWidth={2.5} />
      </DialogTrigger>
      <DialogContent
        className='sm:max-w-[525px] bg-white'
        onClick={(e) => e.stopPropagation()}
      >
        <DialogHeader>
          <DialogTitle>Delete Page?</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <div className='grid gap-4 text-textSecondary text-sm'>
          <p>
            Deleting this page will permanently remove its trained data from
            your knowledge base. This means GPT will no longer use this page to
            generate responses.
          </p>
          <p className='pt-2'>Are you sure you want to proceed?</p>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant={'ghost'} className='bg-background-hover'>
              No
            </Button>
          </DialogClose>

          <Button
            disabled={isDeleting}
            variant={'destructive'}
            onClick={handleDeleteTrainedWebPage}
            className='gap-1'
          >
            {isDeleting ? (
              <Loader2
                strokeWidth={2.5}
                className='w-4 h-4 flex-shrink-0 animate-spin text-white'
              />
            ) : null}
            <span>Yes! Delete</span>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export const DeleteTrainedPageModal = memo(DeleteTrainedPageModal1);
