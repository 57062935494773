import { ICopilotSummary } from 'pages/inbox/inboxInterface';
import { FC } from 'react';
interface SummaryBlockProps {
  summary: ICopilotSummary;
}

export const SummaryBlock: FC<SummaryBlockProps> = ({ summary }) => {
  return (
    <div className='bg-indigo-50 px-4 py-2 rounded-xl shadow-sm w-auto mx-1.5'>
      <h3 className='font-semibold text-xs text-textPrimary'>Summary</h3>
      <p className='font-normal text-xs'>
        <span className='text-textSecondary'>Topic:</span>{' '}
        <span className='text-textPrimary'>{summary?.topic}</span>
      </p>
      <p className='font-normal text-xs'>
        <span className='text-textSecondary'>Ticket Status:</span>{' '}
        <span className='text-textPrimary'>{summary?.ticket_status}</span>
      </p>
      <p className='font-normal text-xs'>
        <span className='text-textSecondary'>Service Status:</span>
        <span className='text-textPrimary'>{summary?.service_status}</span>
      </p>
      <p className='font-normal text-xs'>
        <span className='text-textSecondary'>ICE given:</span>{' '}
        <span className='text-textPrimary'>{summary?.ice_given}</span>
      </p>
    </div>
  );
};
