import { FC } from 'react';
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormDescription,
} from 'libraryV2/ui/form';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from 'libraryV2/ui/collapsible';
import { toast } from 'libraryV2/ui/use-toast';
import { UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import { CustomAgentToolFormData } from '../toolsSchema';
import { Input } from 'libraryV2/ui/input';
import { Textarea } from 'libraryV2/ui/textarea';
import { ChevronDownIcon, ChevronUpIcon, Link } from 'lucide-react';
import { Separator } from 'libraryV2/ui/separator';
import { cn } from 'libraryV2/utils';
import { useDispatch, useSelector } from 'react-redux';
import { ManageWebsitePageList } from './ManageWebsitePageList';
import { ToolDeleteConfirmationModal } from '../ToolDeleteConfirmationModal';
import { AxiosError } from 'axios';
import { ICrawledWebsitePage } from 'pages/raven/interface';

interface WebsiteToolProps {
  toolData: any;
  index: number;
  fieldArrayInstance: UseFieldArrayReturn<{
    tools: any;
  }>;
}

export const WebsiteTool: FC<WebsiteToolProps> = ({
  toolData,
  index,
  fieldArrayInstance,
}) => {
  const dispatch = useDispatch();
  const formInstance = useFormContext<CustomAgentToolFormData>();
  const crawledWebSiteData: ICrawledWebsitePage[] = useSelector(
    (state: any) => state.raven?.websiteCrawlingData[toolData?.data?.url] ?? []
  );
  const formActionMode = useSelector(
    (state: any) => state.raven?.customAgentFormStatus?.mode
  );
  const updateToolOpenStatus = (status: boolean) => {
    fieldArrayInstance.update(index, { ...toolData, isOpen: status });
  };

  const updateFormTrainedPageList = (newPageList: ICrawledWebsitePage[]) => {
    fieldArrayInstance.update(index, {
      ...toolData,
      data: {
        ...toolData?.data,
        pages: newPageList,
      },
    });
  };

  const handleDeleteWebsiteTool = async () => {
    fieldArrayInstance.remove(index);
    dispatch.raven
      .deleteSingleAgentTool(toolData?.id)
      .then(() => {
        toast({
          toastType: 'success',
          title: 'Success',
          description: 'Tool deleted successfully',
        });
      })
      .catch((err: AxiosError<{ error: string }>) => {
        toast({
          title: 'Error',
          description: err.response?.data?.error || 'Something went wrong',
          toastType: 'destructive',
          duration: 2 * 1000,
        });
      });
  };

  return (
    <Collapsible
      open={toolData?.isOpen}
      onOpenChange={updateToolOpenStatus}
      className='rounded-md border border-border-soft'
    >
      <div className='flex items-center justify-between py-1.5 px-4'>
        <CollapsibleTrigger className='flex gap-2 items-center  w-full'>
          <>
            <div className='h-6 w-6 flex items-center justify-center rounded border border-border-soft'>
              <Link className='w-4 h-4 text-textPrimary-disable' />
            </div>
            <span className='text-sm font-semibold text-textPrimary'>
              Get info from website
            </span>
            {toolData?.isOpen ? (
              <ChevronUpIcon className='w-5 h-5 text-textPrimary-disable' />
            ) : (
              <ChevronDownIcon className='w-5 h-5 text-textSecondary-disable' />
            )}
          </>
        </CollapsibleTrigger>
        <ToolDeleteConfirmationModal
          onDeleteConfirm={handleDeleteWebsiteTool}
        />
      </div>

      <CollapsibleContent className='bg-background-container rounded-md'>
        <div className='p-4'>
          <div className='bg-white space-y-3 p-4 rounded-md'>
            <div className='flex w-full gap-2'>
              <FormField
                control={formInstance.control}
                name={`tools.${index}.data.title`}
                render={({ field }) => (
                  <FormItem className='flex-1 space-y-1.5'>
                    <FormLabel>Tool Title</FormLabel>
                    <span className='text-red-500'>*</span>
                    <FormControl>
                      <Input
                        type='text'
                        className='w-full h-9 p-2 rounded-md'
                        placeholder='Enter tool title'
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {/* <FormField
                control={formInstance.control}
                name={`tools.${index}.data.modelUsed`}
                render={({ field }) => (
                  <FormItem className='flex-1 space-y-1.5'>
                    <FormLabel>
                      Model Used
                      <span className='text-red-500'>*</span>
                    </FormLabel>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger
                          disabled={availableEmbedModelList?.length === 0}
                          className='w-full focus:ring-primary'
                        >
                          <SelectValue placeholder='Select a model' />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent className='bg-white mt-1'>
                        <SelectGroup>
                          {availableEmbedModelList?.map((model) => (
                            <SelectItem value={model?.id}>
                              {model?.title}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>

                    <FormDescription
                      className={cn('text-textSecondary font-normal', {
                        hidden: !!Object.keys(formInstance.formState.errors)
                          .length,
                      })}
                    ></FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              /> */}
            </div>

            <FormField
              control={formInstance.control}
              name={`tools.${index}.data.description`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tool description</FormLabel>
                  <span className='text-red-500'>*</span>
                  <FormControl>
                    <Textarea
                      {...field}
                      style={{ resize: 'vertical' }}
                      autoResize={false}
                      className='border-border shadow-sm focus:border-green-500 min-h-[80px] focus-visible:ring-0'
                      placeholder="Explain the tool's purpose"
                      rows={3}
                    />
                  </FormControl>
                  <FormDescription
                    className={cn('text-textSecondary font-normal', {
                      hidden: !!Object.keys(formInstance.formState.errors)
                        .length,
                    })}
                  >
                    Briefly describe how this tool will support the agent's
                    task.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <Separator />
        <div className='p-4'>
          <div className='bg-white p-4 flex flex-col gap-4 rounded'>
            <FormField
              control={formInstance.control}
              name={`tools.${index}.data.url`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Website Url
                    <span className='text-red-500'>*</span>
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type='text'
                      className='w-full h-9 p-2 rounded-md'
                      placeholder='Enter website url'
                      disabled={
                        formActionMode === 'EDIT' &&
                        toolData.data?.pages?.length > 0
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <ManageWebsitePageList
              toolIndex={index}
              crawledWebSiteData={crawledWebSiteData}
              updateFormTrainedPageList={updateFormTrainedPageList}
            />
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};
