import { Navigation, PenLine } from 'lucide-react';
import useCommentEdit from 'pages/inbox/hooks/useCommentEdit';
import {
  ICopilotConversationBlock,
  SendEmailMessageRequestBodyInterface,
  SendGmailMessageRequestBodyInterface,
  SendMessageRequestBodyInterface,
  TicketActionInterface,
  TicketInterface,
} from 'pages/inbox/inboxInterface';
import { FC } from 'react';
import {
  customEmailChannelTypes,
  emailChannels,
  feedChannels,
} from 'utilities/utils';
import { connect } from 'react-redux';
import { normalizeEmailSubject } from 'pages/inbox/utils/functions';

interface EditableTextBlockProps {
  conversation: ICopilotConversationBlock;
  ticketActions: TicketActionInterface[];
  selectedTicket: TicketInterface;
  conversationData: any;
  userId: number;
  blockIndex: number;
  setInputValue: (val: string) => void;
  sendMessegengerChannelMessage: (
    requestBody: SendMessageRequestBodyInterface
  ) => Promise<void>;
  sendFeedChannelMessage: (
    requestBody: SendMessageRequestBodyInterface
  ) => Promise<void>;
  sendEmailChannelMessage: (
    requestBody:
      | SendEmailMessageRequestBodyInterface
      | SendGmailMessageRequestBodyInterface
  ) => Promise<void>;
}

const EditableTextBlock: FC<EditableTextBlockProps> = ({
  conversation,
  ticketActions,
  setInputValue,
  selectedTicket,
  sendMessegengerChannelMessage,
  sendFeedChannelMessage,
  userId,
  blockIndex,
  conversationData,
  sendEmailChannelMessage,
}) => {
  const { updateComment, commentEditData } = useCommentEdit();
  let platformType = selectedTicket?.customer_platform_type;

  let isDirectMessageAllowed = () => {
    if (!Array.isArray(ticketActions)) {
      return false;
    }

    let directAction = ticketActions.filter(
      (actionItem) =>
        actionItem.action === 'direct_message' && actionItem.is_allowed
    );

    return directAction.length > 0;
  };

  const handleMessengerChannelMessage = async (
    messageRequestBody: SendMessageRequestBodyInterface
  ) => {
    await sendMessegengerChannelMessage(messageRequestBody);
  };

  const handleFeedChannelMessage = async (
    messageRequestBody: SendMessageRequestBodyInterface
  ) => {
    await sendFeedChannelMessage(messageRequestBody);
  };

  const handleSendToChatPlatform = (inputText: string) => {
    //check selected ticket is assaigned to the agent
    if (userId !== selectedTicket?.assigned_agent) {
      return;
    }

    const isFeedChannel = feedChannels.includes(platformType);

    if (isFeedChannel && commentEditData.isEditing) {
      updateComment(inputText);
    } else if (isFeedChannel) {
      const payload: SendMessageRequestBodyInterface = {
        text: inputText,
        audio: null,
        image: null,
        action: 'public_reply',
      };

      handleFeedChannelMessage(payload);
    } else {
      let messageRequestPayload: any = {
        text: inputText,
        audio: null,
        image: null,
        template: null,
        action: 'direct_message',
      };

      handleMessengerChannelMessage(messageRequestPayload);
    }
  };

  const handleEmailChannelMessage = async (
    messageRequestBody:
      | SendEmailMessageRequestBodyInterface
      | SendGmailMessageRequestBodyInterface
  ) => {
    await sendEmailChannelMessage(messageRequestBody);
  };

  const getLastValidConversation = (conversations: any[]): any | null => {
    for (let i = 0; i < conversations.length; i++) {
      if (conversations[i].type === 'message') {
        return conversations[i];
      }
    }
    return null;
  };

  const getEmailReferences = (lastValidConversation: any) => {
    let emailReferences = !!lastValidConversation
      ? lastValidConversation?.dataV2?.email_data?.references ?? ''
      : '';
    emailReferences = emailReferences.trim();

    if (emailReferences.includes(lastValidConversation?.thread_id)) {
      // if not thread_id in first then move it to first
      if (!emailReferences.startsWith(lastValidConversation?.thread_id)) {
        // Remove the thread_id from its current position
        emailReferences = emailReferences.replace(
          lastValidConversation?.thread_id,
          ''
        );

        emailReferences =
          lastValidConversation?.thread_id + ' ' + emailReferences;
      }
    } else {
      emailReferences =
        (lastValidConversation?.thread_id ?? '') + ' ' + emailReferences;
    }

    let references =
      emailReferences + ' ' + lastValidConversation?.conversation_id;
    return references.trim();
  };

  const handleSendToEmailPlatform = (inputText: string) => {
    let payload:
      | SendEmailMessageRequestBodyInterface
      | SendGmailMessageRequestBodyInterface = {
      text: inputText,
      to: selectedTicket?.customer_email || '',
      cc_emails: '',
      bcc_emails: '',
      subject: conversationData[conversationData.length - 1]?.subject,
      thread_id: conversationData[conversationData.length - 1]?.thread_id ?? '',
      attachment: '',
      attachment_type: null,
      action: 'direct_message',
    };

    if (
      customEmailChannelTypes.includes(selectedTicket?.customer_platform_type)
    ) {
      const lastValidConversation = !conversationData
        ? null
        : getLastValidConversation(conversationData);

      const currentSubject =
        conversationData && conversationData.length > 0
          ? conversationData[conversationData.length - 1]?.subject
          : '';

      payload = {
        ...payload,
        ...{
          subject: normalizeEmailSubject(currentSubject),
          recipient_emails: selectedTicket?.customer_email || '',
          references: getEmailReferences(lastValidConversation),
          in_reply_to: lastValidConversation?.conversation_id?.trim() ?? '',
        },
      };

      if (!payload.attachment_type) {
        const { attachment_type, ...newPayload } = payload;
        payload = { ...newPayload };
      }
    }

    handleEmailChannelMessage(payload);
  };

  const handleEditClick = () => {
    //check selected ticket is assaigned to the agent
    if (
      userId !== selectedTicket?.assigned_agent ||
      !isDirectMessageAllowed()
    ) {
      return;
    }
    setInputValue(conversation?.title);
  };

  const handleSendFromCopilot = (inputText: string) => {
    if (!emailChannels.includes(selectedTicket?.customer_platform_type)) {
      handleSendToChatPlatform(inputText);
    } else {
      handleSendToEmailPlatform(inputText);
    }
  };

  return (
    <div className='inline-block'>
      <div
        className={`relative group mb-1 block border p-2 rounded-tr-xl rounded-br-xl rounded-bl-xl hover:bg-background-container cursor-pointer ${
          blockIndex === 0 ? 'rounded-tl-sm' : 'rounded-tl-xl'
        } `}
      >
        <span className='text-sm font-normal text-textPrimary'>
          {conversation?.title}
        </span>

        <div className='absolute top-[-28px] right-0 p-1 hidden group-hover:block '>
          <div className='flex gap-1 border rounded-lg px-1 py-0.5 bg-white z-30 shadow-lg'>
            <div
              onClick={() => handleSendFromCopilot(conversation?.title)}
              className='cursor-pointer p-2 hover:bg-gray-200 border-0 rounded-md'
            >
              <Navigation size={15} />
            </div>
            <div
              className='cursor-pointer p-2 hover:bg-gray-200 border-0 rounded-md'
              onClick={handleEditClick}
            >
              <PenLine size={15} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state: any) => ({
  userId: state.auth.id,
  ticketActions: state.inbox.ticketActions,
  conversationData: state.inbox.conversationData,
});

const mapDispatch = (dispatch: any) => ({
  sendMessegengerChannelMessage: (
    requestBody: SendMessageRequestBodyInterface
  ) => dispatch.inbox.sendMessegengerChannelMessage(requestBody),
  sendFeedChannelMessage: (requestBody: SendMessageRequestBodyInterface) =>
    dispatch.inbox.sendFeedChannelMessage(requestBody),
  sendEmailChannelMessage: (
    requestBody: SendEmailMessageRequestBodyInterface
  ) => dispatch.inbox.sendEmailChannelMessage(requestBody),
});
// @ts-ignore
export default connect(mapState, mapDispatch)(EditableTextBlock);
