import { Link, navigate } from '@reach/router';
import { Button } from 'libraryV2/ui/button';
import { ChevronLeft, Gem } from 'lucide-react';
import { FC, useEffect, useState } from 'react';
import { CreateCustomAgentSteps } from './stepper';
import { AgentCreationFormContainer } from './AgentCreationFormContainer';
import { CustomAgentFormSteps } from 'pages/raven/utils/content';
import { useDispatch, useSelector } from 'react-redux';
import tokenBalanceBg from 'pages/raven/assets/tokenBalenceBg.svg';
import RavenTrainButtonBg from 'pages/raven/assets/RavenTrainButtonBg.svg';
interface CreateCustomAgentProps {}
export const CreateCustomAgent: FC<CreateCustomAgentProps> = () => {
  const availableBalance = useSelector(
    (state: any) => state.raven?.availableTokens
  );
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [stepCompleted, setStepCompleted] = useState(0);
  const [formSteps, setFormSteps] = useState(
    CustomAgentFormSteps.map((v, _) => ({ ...v, isDone: false }))
  );

  const handleNextStep = () => {
    if (currentStep >= formSteps.length + 1) {
      return;
    }
    setStepCompleted(currentStep);
    setFormSteps((oldSteps) => {
      return oldSteps.map((v) => {
        if (v.id !== currentStep) {
          return v;
        }
        return { ...v, isDone: true };
      });
    });
    setCurrentStep((old) => {
      return old + 1;
    });
  };

  const handlePreviousStep = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 0));
  };

  const renderPageHeader = () => {
    return (
      <>
        <div className='flex gap-3 items-center'>
          <Button
            variant={'outline'}
            className='h-8 w-8 text-textPrimary p-0'
            onClick={() => navigate(-1)}
          >
            <ChevronLeft className='h-4 w-4 text-textPrimary' />
          </Button>
          <h1 className='text-textPrimary text-xl leading-7 font-bold'>
            Create Custom Agent
          </h1>
        </div>
        <div
          style={{
            display: 'none',
            background:
              'linear-gradient(249.05deg, #FFC9DD 6.13%, #E7E7F8 97.18%)',
            padding: '1px',
            borderRadius: '9999px',
          }}
        >
          <div
            style={{
              backgroundImage: `url(${tokenBalanceBg})`,
              backgroundSize: 'cover',
              borderRadius: '9999px',
            }}
            className='h-8 flex gap-2.5 items-center text-textPrimary bg-background-hover px-1.5'
          >
            <Gem
              strokeWidth={2.5}
              className='text-purple-500 w-3.5 h-3.5 ml-1'
            />
            <p>
              <span className='text-purple-500 font-bold text-xs'>
                {availableBalance}
              </span>{' '}
              <span className='text-xs font-medium'>Tokens Left</span>
            </p>
            {/* @ts-ignore */}
            <Link
              to={''}
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), url(${RavenTrainButtonBg})`,
                backgroundSize: 'cover',
              }}
              className='font-medium text-xs rounded-full px-2.5 py-0.5 text-white'
            >
              Buy Tokens
            </Link>
          </div>
        </div>
      </>
    );
  };

  const handleStep = (step = 1) => {
    setCurrentStep(step);
  };

  useEffect(() => {
    dispatch.raven.fetchDefaultAgentConfig().catch(console.error);
    return () => {
      dispatch.raven.resetCreateCustomAgentFormData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id='raven-manage-agent-container'
      className='min-h-[calc(100vh-2px)] w-full flex-1 flex flex-col text-textPrimary ltr:border-l rtl:border-r bg-white border-border'
    >
      <div className='w-full h-[52px] border-border-soft border-b flex justify-between items-center px-4'>
        {renderPageHeader()}
      </div>
      <div className='flex flex-1'>
        <div
          id='raven-form-step-container'
          className='w-[230px] p-3 h-fit flex-shrink-0'
        >
          <CreateCustomAgentSteps
            steps={formSteps}
            currentStep={currentStep}
            stepCompleted={stepCompleted}
            onStepSelect={handleStep}
          />
        </div>
        <div className='flex w-full flex-grow border-border ltr:border-l rtl:border-r  h-auto'>
          <AgentCreationFormContainer
            currentStep={formSteps?.find((s) => s.id === currentStep)!}
            onNextStep={handleNextStep}
            onPreviousStep={handlePreviousStep}
          />
        </div>
      </div>
    </div>
  );
};
