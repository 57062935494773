import { FC } from 'react';
import { ActionBlock } from './messageBlocks/ActionBlock';
import EditableTextBlock from './messageBlocks/EditableTextBlock';
import { ReadOnlyBlock } from './messageBlocks/ReadOnlyBlock';
import {
  ICopilotConversation,
  ICopilotConversationBlock,
  TicketInterface,
} from 'pages/inbox/inboxInterface';

interface ChatBlockProps {
  conversation: ICopilotConversationBlock;
  setInputValue: (val: string) => void;
  setLoading: (val: boolean) => void;
  setCopilotConversationData: React.Dispatch<
    React.SetStateAction<ICopilotConversation[]>
  >;
  selectedTicket: TicketInterface;
  blockIndex: number;
}

export const ChatBlock: FC<ChatBlockProps> = ({
  conversation,
  setInputValue,
  selectedTicket,
  blockIndex,
  setLoading,
  setCopilotConversationData,
}) => {
  switch (conversation?.type) {
    case 'clickable-button-action':
      return (
        <ActionBlock
          setLoading={setLoading}
          conversation={conversation}
          blockIndex={blockIndex}
          setCopilotConversationData={setCopilotConversationData}
        />
      );
    case 'editable':
      return (
        <EditableTextBlock
          conversation={conversation}
          setInputValue={setInputValue}
          selectedTicket={selectedTicket}
          blockIndex={blockIndex}
        />
      );
    case 'non_editable':
      return (
        <ReadOnlyBlock conversation={conversation} blockIndex={blockIndex} />
      );
    default:
      return null;
  }
};
