import React, { useEffect } from 'react';
import useSSOAuth from './useSSOAuth';
import { useToast } from 'libraryV2/ui/use-toast';
import { navigate } from '@reach/router';

const AuthCallback: React.FC = () => {
  let { getAuthToken } = useSSOAuth();
  let { toast } = useToast();

  useEffect(() => {
    try {
      // Get URL parameters
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const sessionState = urlParams.get('session_state');
      const stateParam = urlParams.get('state');

      if (!code || !sessionState) {
        toast({
          title: `API failed to provide account information.`,
          description: 'Please try again or contact support',
        });
        navigate('/');
        throw new Error('Missing required parameters');
      }

      let callSSOAuthToken = async () =>
        await getAuthToken({
          code: code,
          state: stateParam,
          session_state: sessionState,
        });
      callSSOAuthToken();
    } catch (error) {
      console.error('Authentication error:', error);
      toast({
        title: `API failed to provide account information.`,
        description: 'Please try again or contact support',
      });
      navigate('/');
    }
    // eslint-disable-next-line
  }, []);

  return null;
};

export default AuthCallback;
