import { ICustomAgentFormSteps } from 'pages/raven/interface';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { toast } from 'libraryV2/ui/use-toast';
import { Button } from 'libraryV2/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'libraryV2/ui/form';
import { Input } from 'libraryV2/ui/input';
import { FC, useRef } from 'react';
import { cn } from 'libraryV2/utils';
import { Textarea } from 'libraryV2/ui/textarea';
import { useCreateCustomAgent } from 'pages/raven/hooks/useCreateCustomAgent';

interface GeneralInfoProps {
  step: ICustomAgentFormSteps;
  onNextStep: () => void;
}

const GeneralInfoFormSchema = z.object({
  name: z.string().min(1, {
    message: 'This field is required.',
  }),
  public_description: z.string().min(1, {
    message: 'This field is required.',
  }),
});

export const GeneralInfo: FC<GeneralInfoProps> = ({ step, onNextStep }) => {
  const {
    customAgentFormData,
    updateCustomAgentFormData,
    createCustomAgent,
    updateCustomAgent,
  } = useCreateCustomAgent();
  const formContainer = useRef(null);
  // form hook setup
  const form = useForm<z.infer<typeof GeneralInfoFormSchema>>({
    resolver: zodResolver(GeneralInfoFormSchema),
    mode: 'onChange',
    defaultValues: async () => ({
      name: customAgentFormData?.name || '',
      public_description: customAgentFormData?.public_description || '',
    }),
  });

  function onSubmit(data: z.infer<typeof GeneralInfoFormSchema>) {
    let handleCustomAgentAction = customAgentFormData?.id
      ? updateCustomAgent
      : createCustomAgent;
    handleCustomAgentAction({
      title: data?.name,
      description: data?.public_description,
    })
      .then((res) => {
        updateCustomAgentFormData({
          ...customAgentFormData,
          ...data,
          id: res?.id,
        });
        onNextStep();
        toast({
          // @ts-ignore
          title: (
            <p className='text-sm text-primary font-semibold'>Save Changes</p>
          ),
          description: (
            <p className='text-textPrimary'>
              Your changes have been saved successfully.
            </p>
          ),
        });
      })
      .catch((er) => {
        toast({
          toastType: 'destructive',
          title: 'Failed!',
          description: (
            <p className='text-textPrimary'>
              Couldn't save changes. please try again.
            </p>
          ),
        });
      });
  }

  const renderGeneralInfoForm = () => {
    return (
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className='w-full space-y-4'
        >
          <FormField
            control={form.control}
            name='name'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Name <span className='text-red-500'>*</span>
                </FormLabel>
                <FormControl>
                  <Input placeholder='Enter custom agents name' {...field} />
                </FormControl>
                <FormDescription
                  className={cn('text-textSecondary font-normal', {
                    hidden: !!form.formState.errors[field.name],
                  })}
                >
                  e.g., Order Assistant, Refund Bot, Feedback Collector
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='public_description'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Public Description
                  <span className='text-red-500'>*</span>
                </FormLabel>
                <FormControl>
                  <Textarea
                    style={{ resize: 'vertical' }}
                    autoResize={false}
                    placeholder='Enter public descriptions'
                    className='border-border shadow-sm focus:border-green-500 min-h-[80px] focus-visible:ring-0'
                    {...field}
                  />
                </FormControl>
                <FormDescription
                  className={cn('text-textSecondary font-normal', {
                    hidden: !!form.formState.errors[field.name],
                  })}
                >
                  This description will help other human agents to understand
                  the purpose and functionality of this custom agent.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className='w-full flex justify-end gap-2 pt-2'>
            <Button
              type='submit'
              disabled={!form.formState.isValid}
              className={'disabled:opacity-75'}
            >
              Save & Continue
            </Button>
          </div>
        </form>
      </Form>
    );
  };

  const renderFormHeader = () => {
    return (
      <div>
        <h2 className='font-bold text-xl mb-1.5'>General Info</h2>
        <p className='text-sm text-textSecondary'>
          Provide the name and description to help your team understand the
          purpose of this custom agent.
        </p>
      </div>
    );
  };

  return (
    <div
      ref={formContainer}
      data-testid='raven-general-info-section'
      className='flex flex-col gap-6 w-full h-auto flex-1 max-w-2xl mx-auto pt-6'
    >
      {renderFormHeader()}
      <hr />
      {renderGeneralInfoForm()}
    </div>
  );
};
