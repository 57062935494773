import { ICopilotConversationBlock } from 'pages/inbox/inboxInterface';
import { parseBasicMarkdown } from 'pages/inbox/utils/functions';
import { FC } from 'react';
interface ReadOnlyBlockProps {
  conversation: ICopilotConversationBlock;
  blockIndex: number;
}

export const ReadOnlyBlock: FC<ReadOnlyBlockProps> = ({
  conversation,
  blockIndex,
}) => {
  const MarkdownRenderer = (text: string) => {
    const renderMarkdown = (markdown: string) => {
      return { __html: parseBasicMarkdown(markdown) };
    };

    return <div dangerouslySetInnerHTML={renderMarkdown(text)} />;
  };
  return (
    <div
      className={`mb-1 inline-flex border  p-2 rounded-tr-xl rounded-br-xl rounded-bl-xl ${
        blockIndex === 0 ? 'rounded-tl-sm' : 'rounded-tl-xl'
      } `}
    >
      <span className='text-sm font-normal text-textPrimary'>
        {MarkdownRenderer(conversation?.title)}
      </span>
    </div>
  );
};
