import { StoreConnectionStatus } from 'pages/automationWorkflow/interface';
import useAutomationDashboard from '../../../hooks/useAutomationDashboard';
import { Switch } from 'libraryV2/ui/switch';

interface DataTableRowActionsProps {
  row: any;
}

export function SwitchComponent({ row }: DataTableRowActionsProps) {
  const {
    editSelectedUserWorkflowActivation,
    isUpdateSelectedAutomationFlowLoading,
  } = useAutomationDashboard();

  const is_active: boolean = row.getValue('is_active') ?? false;

  const isStoreConnected: boolean =
    row?.original?.connection_status === StoreConnectionStatus.CONNECTED
      ? true
      : false;

  return (
    <>
      {isStoreConnected ? (
        <div className='flex items-center gap-2'>
          <Switch
            disabled={isUpdateSelectedAutomationFlowLoading}
            checked={is_active}
            onCheckedChange={() => {
              editSelectedUserWorkflowActivation({
                flowId: row?.original?.id,
                name: row?.original?.name,
                trigger_code: row?.original?.trigger_code,
                is_active: !is_active,
                trigger_match_unique_ref:
                  row?.original?.trigger_match_unique_ref,
              });
            }}
            className={!is_active ? 'bg-gray-300' : ''}
          />
          {is_active ? (
            <span className='text-sm'>Active</span>
          ) : (
            <span className='text-sm'>Inactive</span>
          )}
        </div>
      ) : (
        <div className='inline-flex items-center bg-red-50 border-0 rounded px-1'>
          <span className='text-red-500 text-xs'>Disconnected</span>
        </div>
      )}
    </>
  );
}
