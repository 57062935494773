import { useDispatch, useSelector } from 'react-redux';
import config from 'utilities/config';
import axios, { AxiosStream } from 'utilities/httpClient';
import {
  CustomAgentDefaultConfig,
  WebPageTrainingResponse,
  WebsiteToolData,
} from '../interface';

export const useCustomAgentTools = () => {
  const dispatch = useDispatch();
  const availableToken = useSelector(
    (state: any) => state?.raven?.availableTokens
  );
  const formActionMode: 'EDIT' | 'CREATE' = useSelector(
    (state: any) => state.raven?.customAgentFormStatus?.mode
  );
  const usableToken = useSelector((state: any) => state?.raven?.usableTokens);
  const scrapeWebsiteContent = ({
    url,
    embedModelId,
    handler,
  }: {
    url: string;
    embedModelId: string;
    handler?: (response: { data: ReadableStream }) => Promise<void>;
  }) => {
    if (!url?.length) {
      return;
    }
    try {
      const payload = {
        domain: url,
        embed_model_id: embedModelId,
      };
      AxiosStream.post(config.raven.crawlWebsite(), payload).then(handler);
      return;
    } catch (error) {
      throw error;
    }
  };
  const customAgentToolFormData = useSelector(
    (state: any) => state.raven?.customAgentFormData?.tools
  );

  const customAgentId = useSelector(
    (state: any) => state?.raven?.customAgentFormData?.id || ''
  );

  const defaultAgentConfig: CustomAgentDefaultConfig = useSelector(
    (state: any) => state?.raven?.defaultAgentConfig
  );

  const updateAgentFormToolData = (newToolsData: any[]) => {
    dispatch.raven.updateCustomAgentForm({
      tools: newToolsData,
    });
  };

  const handleTrainSingleDocument = async (documentId: number) => {
    return dispatch?.raven?.trainSingleDocument({ documentId });
  };
  const hasEnoughToken = (token: number) => {
    return usableToken >= token;
  };
  const updateUsableToken = (token: number) => {
    dispatch?.raven?.updateStateData({
      key: 'usableTokens',
      value: token,
    });
  };
  const updateAvailableToken = (token: number) => {
    dispatch?.raven?.updateStateData({
      key: 'availableTokens',
      value: token,
    });
  };

  const trainMultipleWebPage = async ({
    team_id,
    embed_model_id,
    webPages,
    tool_id,
  }: {
    team_id: string;
    tool_id: string;
    embed_model_id: string;
    webPages: {
      url: string;
    }[];
  }) => {
    const results = await Promise.allSettled(
      webPages.map(
        ({ url }): Promise<WebPageTrainingResponse> =>
          dispatch.raven
            .trainWebPage({
              url: url,
              team_id: team_id,
              tool_id: tool_id,
              embed_model_id: embed_model_id,
            })
            .then((res: any) => res?.data)
      )
    );
    const success: WebPageTrainingResponse[] = [];
    const failed: {
      url: string;
      error: string;
    }[] = [];
    // separate success and failed results
    results.forEach((result, idx) => {
      if (result.status === 'fulfilled') {
        success.push(result.value);
      } else {
        failed.push({
          url: webPages[idx].url,
          error: result.reason?.response?.data?.message || 'Unknown error',
        });
      }
    });
    return { success, failed };
  };

  const bulkTrainBWebPage = async ({
    webPages,
    tool_id,
    handler,
  }: {
    tool_id: string;
    handler: (response: { data: ReadableStream }) => Promise<void>;
    webPages: {
      url: string;
    }[];
  }) => {
    const webPageUrlList = webPages.map((v) => v.url);
    const payload = {
      urls: webPageUrlList,
      tool_id: tool_id,
    };
    try {
      AxiosStream.post(config.raven.bulkWebsiteTrain(), payload).then(handler);
      return;
    } catch (error) {
      throw error;
    }
  };
  const syncBulkTrainBWebPage = async ({
    webPages,
    tool_id,
  }: {
    tool_id: string;
    webPages: {
      url: string;
    }[];
  }) => {
    const webPageUrlList = webPages.map((v) => v.url);
    const payload = {
      urls: webPageUrlList,
      tool_id: tool_id,
    };
    try {
      const result = await axios.post(config.raven.bulkWebsiteTrain(), payload);
      return result.data;
    } catch (error) {
      throw error;
    }
  };

  const createMultipleWebsiteTool = async (
    websiteToolData: WebsiteToolData[]
  ) => {
    const results = await Promise.allSettled(
      websiteToolData.map(
        (singleTool): Promise<WebPageTrainingResponse> =>
          dispatch.raven
            .updateWebsiteTool({
              payload: {
                name: singleTool?.data?.title || '',
                description: singleTool?.data?.description || '',
                base_url: singleTool?.data?.url || null,
              },
              toolId: singleTool?.id,
            })
            .then((res: any) => {
              return res?.data;
            })
      )
    );
    return results;
  };

  return {
    customAgentId,
    availableToken,
    usableToken,
    defaultAgentConfig,
    formActionMode,
    createMultipleWebsiteTool,
    customAgentToolFormData,
    updateAgentFormToolData,
    updateAvailableToken,
    updateUsableToken,
    hasEnoughToken,
    trainMultipleWebPage,
    scrapeWebsiteContent,
    handleTrainSingleDocument,
    bulkTrainBWebPage,
    syncBulkTrainBWebPage,
  };
};
