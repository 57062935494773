import React from 'react';
import AuthHeader from './components/AuthHeader';
import bg from './../../assets/images/authOnboardBG.svg';
import LoginFormSection from './components/LoginFormSection';
import { connect } from 'react-redux';
import { toaster } from 'evergreen-ui';
import { navigate, Redirect } from '@reach/router';
import { authData } from '../../utilities/content';
import { getQueryToken } from '../../utilities/utils';

import {
  acceptInvitationAPiResponseType,
  loginProps,
  loginAPiResponse,
} from './inteface';
import usePartner, {
  PartnerDomainList,
} from 'components/customHooks/usePartner';
import LoginFooter from './components/LoginFooter';
import LanguageDropDown from 'library/language/languageDropDown';
import useTranslation from 'components/customHooks/useTranslation';

interface ILoginData {
  username: string;
  password: string;
  partner?: string;
  dashboard_language?: string;
}

interface Props {
  auth: any;
  loginLoading: boolean;
  fetchProject: () => void;
  updateInvitationToken: (token: string) => void;
  validateInvitationToken: (token: string) => boolean;
  login: (payload: loginProps) => Promise<loginAPiResponse>;
  acceptTeamInvitation: (
    token: string
  ) => Promise<acceptInvitationAPiResponseType>;
}

const Login: React.FC<Props> = ({
  auth,
  login,
  loginLoading,
  fetchProject,
  acceptTeamInvitation,
  updateInvitationToken,
  validateInvitationToken,
}) => {
  const [email, setemail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [errorCode, setErrorCode] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [partnerDomain, setPartnerDomain] = React.useState<string>('');
  const [loginFailed, setLoginFailed] = React.useState<boolean>(false);
  const { isParnterDomain, currentDomain } = usePartner();

  const { authT, dashboardLanguage } = useTranslation();

  let invitationToken = getQueryToken('invitation_token');

  const registerLink =
    invitationToken.length !== 0
      ? `${authData.registerInfo.link}?invitation_token=${invitationToken}`
      : authData.registerInfo.link;

  const isAuthenticated = auth.access !== '';

  const handleChange = (key: string, value: any) => {
    if (key === 'email') {
      setemail(value.toLowerCase());
    } else if (key === 'password') {
      setPassword(value);
    } else if (key === 'loginFailed') {
      setLoginFailed(value);
    }
  };

  const acceptInvitation = async (token: string) => {
    const invitationResponse: acceptInvitationAPiResponseType =
      await acceptTeamInvitation(token);
    if (invitationResponse?.status === 200) {
      await fetchProject();
      toaster.success(authT('Invitation accepted'));
    } else if (invitationResponse?.status === 500) {
      toaster.danger(
        authT('Something went wrong. Could Not Accept Invitation')
      );
    } else {
      toaster.danger(invitationResponse?.data?.error);
    }
  };

  const handleSubmit = async () => {
    const loginData: ILoginData = {
      username: email,
      password: password,
      dashboard_language: dashboardLanguage,
    };
    if (isParnterDomain) {
      loginData.partner = currentDomain;
    }

    if (!!email && !!password) {
      let res = await login(loginData);
      if (res?.status === 200) {
        const isTwoFactorAuthEnabled =
          !res?.data?.success && !!res?.data?.otp && res?.data?.otp;
        if (isTwoFactorAuthEnabled) {
          navigate(
            `/verify-otp?token=${
              !!res?.data?.token ? res?.data?.token : ''
            }&email=${email}`
          );
        } else {
          if (auth.invitationToken.length !== 0) {
            const validationResponse = await validateInvitationToken(
              auth.invitationToken
            );
            if (!!validationResponse) acceptInvitation(auth.invitationToken);
            updateInvitationToken('');
          }

          const shopifyToken = localStorage.getItem(
            'shopify_installation_request_id'
          );

          let isZidConnection = false;
          const previousPath = localStorage.getItem('previousPath') || '';
          if (
            !!previousPath &&
            previousPath.toLowerCase().includes('zid-connect')
          ) {
            isZidConnection = true;
            localStorage.removeItem('previousPath');
          }

          if (!!shopifyToken) {
            navigate('/shopify-connect');
          } else if (!!isZidConnection) {
            navigate(previousPath);
          } else if (auth.hasOnboarding) {
            navigate('/onboarding');
          } else {
            navigate('/dashboard');
          }
        }
      } else if (res?.status === 500) {
        setLoginFailed(true);
        setErrorMessage(authT('Something went wrong. Try again'));
      } else {
        setLoginFailed(true);
        setErrorCode(res?.data?.code);
        setErrorMessage(res?.data?.error);
      }
    } else {
      setLoginFailed(true);
      setErrorMessage(authT('Provide Email and Password to login'));
    }
  };

  const getLoginForm = () => {
    return (
      <div
        style={{ backgroundImage: `url(${bg})` }}
        className='flex flex-col items-center justify-start w-screen h-screen overflow-x-auto bg-cover bg-cente sm:justify-center'
      >
        <div className=' absolute top-[5vh] right-[24px]'>
          <LanguageDropDown />
        </div>
        <div className='flex flex-col justify-center h-screen px-10 py-8 bg-white rounded shadow sm:w-full sm:max-w-md sm:block sm:h-auto'>
          <AuthHeader
            title={authT(authData.login.title)}
            partnerDomainName={partnerDomain}
            link={registerLink}
            subtitle={authT(authData.registerInfo.text)}
            linkText={authT(authData.registerInfo.linkTitle)}
          />
          <LoginFormSection
            email={email}
            content={authData}
            password={password}
            loading={loginLoading}
            loginFailed={loginFailed}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            errorCode={errorCode}
            errorMessage={errorMessage}
            invitationToken={invitationToken}
          />
        </div>
        <div className='relative w-full'>
          <LoginFooter />
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    const domainName = window.location.hostname;
    if (PartnerDomainList.includes(domainName)) {
      setPartnerDomain(domainName);
    }
    if (invitationToken.length !== 0) {
      updateInvitationToken(invitationToken);
    }
    if (invitationToken.length !== 0 && isAuthenticated) {
      const tokenValidation = async () =>
        await validateInvitationToken(invitationToken);

      let validationResponse = tokenValidation();
      if (!!validationResponse) {
        acceptInvitation(invitationToken);
      }
      updateInvitationToken('');
    }
    // eslint-disable-next-line
  }, []);

  return isAuthenticated ? (
    <Redirect from='/' to='/dashboard' noThrow />
  ) : (
    getLoginForm()
  );
};

const mapState = (state: any) => ({
  auth: state.auth,
  loginLoading: state.loading.effects.auth.login,
});

const mapDispatch = (dispatch: any) => ({
  login: (payload: loginProps) => dispatch.auth.login(payload),
  acceptTeamInvitation: (token: string) =>
    dispatch.auth.acceptTeamInvitation(token),
  updateInvitationToken: (token: string) =>
    dispatch.auth.updateInvitationToken(token),
  fetchProject: () => dispatch.dashboard.fetchProject(),
  validateInvitationToken: (token: string) =>
    dispatch.auth.validateInvitationToken(token),
});

export default connect(mapState, mapDispatch)(Login);
