import { Button } from 'libraryV2/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'libraryV2/ui/dropdown-menu';
import { Fragment, useState } from 'react';
import { UtilityContents, toast, useSelector } from '../../../../../export';
import {
  IUserActionFlowTableActionTypes,
  StoreConnectionStatus,
} from 'pages/automationWorkflow/interface';
import useAutomationDashboard from '../../../hooks/useAutomationDashboard';
import useTranslation from 'components/customHooks/useTranslation';
import { Ellipsis } from 'lucide-react';
import { getRowActionIcon } from 'pages/automationWorkflow/utility/function';
import { navigate } from '@reach/router';

interface DataTableRowActionsProps {
  row: any;
}

export function DataTableRowActions({ row }: DataTableRowActionsProps) {
  const { t, isRtlLanguage } = useTranslation();
  const { selectedProject } = useSelector((state: any) => state.dashboard);
  const {
    handleDeleteWorkflowConfirmation,
    updateWorkflowPrimitiveDataAfterRename,
    cloneUserActionFlow,
    updateSelectedUserActionFlowData,
  } = useAutomationDashboard();

  const [isModal, setIsModal] = useState(true);

  const isStoreConnected: boolean =
    row?.original?.connection_status === StoreConnectionStatus.CONNECTED
      ? true
      : false;

  const utilityList = !!isStoreConnected
    ? UtilityContents.UserActionFlowTableActionData
    : UtilityContents.UserActionFlowTableActionData.filter(
        (data: IUserActionFlowTableActionTypes) =>
          data?.label !== UtilityContents?.UserActionFlowTableActionLabels?.EDIT
      );

  return (
    <DropdownMenu modal={isModal}>
      <DropdownMenuTrigger asChild>
        <Button
          variant='ghost'
          className='flex h-8 w-8 p-0 data-[state=open]:bg-muted focus:border-traparent'
        >
          <Ellipsis className='h-4 w-4' />
          <span className='sr-only'>{t('Open menu')}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align={isRtlLanguage ? 'start' : 'end'}
        className='w-[160px] bg-white border border-zinc-200'
      >
        {utilityList.map(
          (item: IUserActionFlowTableActionTypes, index: number) => {
            return (
              <Fragment key={index}>
                {item?.label ===
                  UtilityContents.UserActionFlowTableActionLabels.DELETE && (
                  <DropdownMenuSeparator className='bg-gray-200' />
                )}
                <DropdownMenuItem
                  className={`px-4 py-2 hover:bg-gray-50 text-sm text-gray-600 cursor-pointer my-1`}
                  onClick={() => {
                    if (
                      item?.label ===
                      UtilityContents?.UserActionFlowTableActionLabels?.History
                    ) {
                      if (!isStoreConnected) {
                        toast({
                          title: 'No Store Connected',
                          description: 'No active ecommerce is connected',
                        });
                      } else {
                        updateSelectedUserActionFlowData(row?.original);
                        navigate(
                          `/projects/${selectedProject?.id}/workflow-automation/${row?.original?.id}/history`
                        );
                      }
                    } else if (
                      item?.label ===
                      UtilityContents?.UserActionFlowTableActionLabels?.EDIT
                    ) {
                      updateSelectedUserActionFlowData(row?.original);
                      navigate(
                        `/projects/${selectedProject?.id}/workflow-automation/${row?.original?.id}`
                      );
                    } else if (
                      item?.label ===
                      UtilityContents.UserActionFlowTableActionLabels.DELETE
                    ) {
                      handleDeleteWorkflowConfirmation(row?.original);
                      setIsModal(false);
                    } else if (
                      item?.label ===
                      UtilityContents.UserActionFlowTableActionLabels.RENAME
                    ) {
                      updateWorkflowPrimitiveDataAfterRename(row?.original);
                      setIsModal(false);
                    } else if (
                      item?.label ===
                      UtilityContents.UserActionFlowTableActionLabels.DUPLICATE
                    ) {
                      cloneUserActionFlow(row?.original);
                    }
                  }}
                >
                  <div className='flex items-center gap-2 text-sm text-textPrimary'>
                    {getRowActionIcon(item?.label, 15)}
                    {item?.label}
                  </div>
                </DropdownMenuItem>
              </Fragment>
            );
          }
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
