import React, { Fragment, useRef } from 'react';
import CopilotAvator from '../../assets/images/copilot-avator.svg';
import { X, ChevronDown, Sparkles } from 'lucide-react';
import Pusher from 'pusher-js';
import CopilotBg from 'pages/inbox/assets/images/inbox-copilot-bg.svg';
import { ChatBlock } from './components/ChatBlock';
import {
  ICopilotConversation,
  ICopilotConversationBlock,
  ICopilotHistory,
  ICopilotSummary,
  TicketInterface,
} from 'pages/inbox/inboxInterface';
import { QuickReplayBlock } from './components/messageBlocks/QuickReplayBlock';
import { useSelector } from 'react-redux';
import { SummaryBlock } from './components/messageBlocks/SummaryBlock';

interface Props {
  isOpenCopilot: boolean;
  setIsOpenCopilot: (val: boolean) => void;
  setInputValue: (val: string) => void;
  selectedTicket: TicketInterface;
  fetchCopilotConversationList: (requestBody: {
    agent_id: number;
    ticket_id: number;
  }) => Promise<ICopilotHistory>;
}

const Copilot: React.FC<Props> = ({
  isOpenCopilot,
  setIsOpenCopilot,
  setInputValue,
  selectedTicket,
  fetchCopilotConversationList,
}) => {
  const messagesEndRef = useRef(null);
  const [copilotConversationData, setCopilotConversationData] = React.useState<
    ICopilotConversation[]
  >([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [summary, setSummary] = React.useState<ICopilotSummary>();
  const agent_id: number = useSelector((state: any) => state.auth?.id);
  React.useEffect(() => {
    //fetch method to get previous conversation data
    const fetchData = async () => {
      const res = await fetchCopilotConversationList({
        ticket_id: selectedTicket?.id,
        agent_id: agent_id,
      });
      setSummary(res?.summary);
      setCopilotConversationData(res?.history);
    };

    fetchData();

    // eslint-disable-next-line
  }, [selectedTicket?.id]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  React.useEffect(() => {
    let pusherKey = !!process.env.REACT_APP_PUSHER_KEY
      ? process.env.REACT_APP_PUSHER_KEY
      : '';
    let clusterKey = !!process.env.REACT_APP_PUSHER_CLUSTER
      ? process.env.REACT_APP_PUSHER_CLUSTER
      : '';
    const pusher = new Pusher(pusherKey, {
      cluster: clusterKey,
    });

    const messageChannel = pusher.subscribe(
      `chat-copilot_${selectedTicket?.id}_${agent_id}`
    );

    messageChannel.bind(`copilot`, (event: ICopilotConversation) => {
      // no need to update array as we are updating it manually while button click;
      if (event.type !== 'quick_reply') {
        setCopilotConversationData((prevData) => {
          return [...prevData, event];
        });
        if (event?.summary) {
          setSummary(event?.summary);
        }
      }
    });

    return () => {
      messageChannel.unbind_all();
      messageChannel.unsubscribe();
      pusher.disconnect();
    };
    //eslint-disable-next-line
  }, [selectedTicket?.id]);

  React.useEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line
  }, [copilotConversationData]);

  const renderCopilotHeader = () => {
    return (
      <div className='flex items-center justify-between px-3 py-3 border-b z-20'>
        <div>
          <span className='font-semibold text-base'>Copilot</span>
        </div>
        <div onClick={() => setIsOpenCopilot(false)} className='cursor-pointer'>
          <X size={20} />
        </div>
      </div>
    );
  };

  const renderCopilotFooter = () => {
    return (
      <div className='flex items-center justify-center py-3 border-t z-30 w-full'>
        <div>
          <span className='font-medium text-xss text-textSecondary text-center'>
            Responses may not always be accurate. {/* link is not ready yet */}
            {/* <a href='https://docs.myalice.ai' className='text-link underline'>
              Learn more
            </a> */}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className='h-[89vh] border z-50 bg-white rounded-2xl relative shadow-xl flex flex-col w-auto'>
      {renderCopilotHeader()}
      <div className='p-2 flex-grow max-h-[77vh] overflow-y-auto scrollbar-hide'>
        <div className='flex flex-col items-center justify-center text-center mt-6 mb-6'>
          <img src={CopilotAvator} alt='avatar' className='mb-2' />
          <span className='font-medium text-xs text-textSecondary'>
            Your AI-powered assistant, ready to help!
          </span>
        </div>
        {!!summary && (
          <div className='mb-5'>
            <SummaryBlock summary={summary} />
          </div>
        )}

        <div className='mx-1.5'>
          {copilotConversationData?.map(
            (item: ICopilotConversation, index: number) => {
              return (
                <Fragment key={index}>
                  {item?.type === 'card' ? (
                    <div className='flex items-start gap-2 mb-1'>
                      <div className='flex-shrink-0'>
                        <img
                          src={CopilotAvator}
                          alt='avatar'
                          className='h-8 w-8'
                        />
                      </div>

                      <div className='flex-1 block'>
                        {item?.data?.map(
                          (
                            dataItem: ICopilotConversationBlock,
                            index: number
                          ) => (
                            <ChatBlock
                              key={index}
                              conversation={dataItem}
                              setInputValue={setInputValue}
                              selectedTicket={selectedTicket}
                              blockIndex={index}
                              setLoading={setLoading}
                              setCopilotConversationData={
                                setCopilotConversationData
                              }
                            />
                          )
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className='flex items-center justify-end mb-1'>
                      <div className='flex-shrink-0'></div>
                      <div className='max-w-[85%]'>
                        <QuickReplayBlock quickReply={item} />
                      </div>
                    </div>
                  )}
                </Fragment>
              );
            }
          )}
          {loading ? (
            <div className='flex items-start gap-2 mb-1'>
              <div className='flex-shrink-0'>
                <img src={CopilotAvator} alt='avatar' className='h-8 w-8' />
              </div>

              <div className='flex-1 block items-center p-1.5'>
                <span className='text-xs text-textSecondary'>
                  I’m on it, please wait...
                </span>
              </div>
            </div>
          ) : null}
        </div>
        <div ref={messagesEndRef}></div>
      </div>

      {isOpenCopilot && (
        <div
          onClick={() => setIsOpenCopilot(false)}
          className='absolute w-8 h-[125px] left-[-30px] top-1/2 transform -translate-y-1/2 z-20 cursor-pointer'
          style={{
            backgroundImage: `url(${CopilotBg})`,
            backgroundSize: 'auto',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className='relative flex -rotate-90 bottom-[-37px] -left-2 text-white'>
            <div className='absolute right-0 z-10 flex items-center gap-1'>
              <Sparkles fill='white' size={14} className='rotate-90' />
              <span className='text-xs'>Copilot</span>
              <ChevronDown size={14} />
            </div>
          </div>
        </div>
      )}
      <div>{renderCopilotFooter()}</div>
    </div>
  );
};

export default Copilot;
