import { Button } from 'libraryV2/ui/button';
import { Separator } from 'libraryV2/ui/separator';
import { ICustomAgentFormSteps } from 'pages/raven/interface';
import { FC, useState } from 'react';
import { ChatMessages } from './ChatMessages';
import { useCreateCustomAgent } from 'pages/raven/hooks/useCreateCustomAgent';
import { cn } from 'libraryV2/utils';
import { toast } from 'libraryV2/ui/use-toast';
import { ActiveAgentModal } from 'pages/raven/components/common/ActiveAgentModal';

interface TestCustomAgentProps {
  step: ICustomAgentFormSteps;
  onNextStep: () => void;
  onPreviousStep: () => void;
}

export const TestCustomAgent: FC<TestCustomAgentProps> = ({
  onNextStep,
  onPreviousStep,
}) => {
  const [isAgentConnected, setIsAgentConnected] = useState(false);
  const [isChatHistoryCleared, setIsChatHistoryCleared] = useState(false);
  const { customAgentFormData, deleteAgentChatHistory } =
    useCreateCustomAgent();

  const handleAgentChatHistoryClear = () => {
    deleteAgentChatHistory(customAgentFormData?.id).then(() => {
      setIsChatHistoryCleared(true);
      toast({
        toastType: 'success',
        title: 'Success',
        description: 'Chat history successfully deleted.',
      });
    });
  };
  return (
    <div
      data-testid='raven-general-info-section'
      className='flex flex-col h-[calc(100vh-56px)] max-w-2xl mx-auto w-full py-6'
    >
      <div className='flex flex-col flex-1 gap-3 min-h-0'>
        <div className='space-y-1.5'>
          <div className='w-full flex justify-between'>
            <h1 className='font-bold flex text-xl leading-6'>
              Test
              <span
                className={cn(
                  'w-2 h-2 block rounded-full',
                  isAgentConnected ? 'bg-primary' : 'bg-red-500'
                )}
              ></span>
            </h1>
            <Button
              variant={'ghost'}
              className='h-6 text-sm font-normal'
              onClick={handleAgentChatHistoryClear}
            >
              Clear Chat History
            </Button>
          </div>

          <p className='text-sm font-medium text-gray-600'>
            Chat with your agent to see how it performs and make sure it's ready
            for action.
          </p>
        </div>
        <Separator />
        <div className='flex flex-col flex-1 min-h-0'>
          <ChatMessages
            isChatHistoryCleared={isChatHistoryCleared}
            customAgentId={customAgentFormData?.id}
            setIsAgentConnected={setIsAgentConnected}
          />
        </div>
        <div className='flex justify-between w-full'>
          <Button variant='outline' onClick={onPreviousStep}>
            Back
          </Button>
          <div className='flex items-center gap-2'>
            <ActiveAgentModal customAgentId={customAgentFormData?.id} />

            <Button onClick={onNextStep}>Next</Button>
          </div>
        </div>
      </div>
    </div>
  );
};
