'use client';

import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { DataTable } from 'libraryV2/ui/data-table';
import { useState, useCallback, useRef } from 'react';
import { DataTableToolbar } from './component/DataTableToolbar';
import { useSelector, UtilityContents } from '../../../../export';
import useAutomationDashboard from '../../hooks/useAutomationDashboard';
import { IUserFlowTableDataTypes } from 'pages/automationWorkflow/interface';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'libraryV2/ui/dialog';
import { Button } from 'libraryV2/ui/button';
import { Input } from 'libraryV2/ui/input';
import emptyPageIcon from 'assets/icons/emptyPageIcon.svg';
import emptySearchIcon from 'assets/icons/emptySearchIcon.svg';
import { LoaderCircle } from 'lucide-react';
import { DataTableRowActions } from './component/DataTableRowActions';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  selectedTriggerTypes: string[];
  setSelectedTriggerTypes: (triggerType: string[]) => void;
  selectedSources: number[];
  setSelectedSources: (src: number[]) => void;
  setSelectedStatus: (status: boolean | undefined) => void;
  setSearchKey: (key: string) => void;
  searchKey: string;
  limit: number;
  setLimit: (limit: number) => void;
  offset: number;
  setOffset: (val: number) => void;
  loading: boolean;
}

export default function AutomationTable<TData, TValue>({
  columns,
  data,
  selectedTriggerTypes,
  setSelectedTriggerTypes,
  selectedSources,
  setSelectedSources,
  setSelectedStatus,
  setSearchKey,
  searchKey,
  limit,
  setLimit,
  offset,
  setOffset,
  loading,
}: DataTableProps<TData, TValue>) {
  const {
    createdTotalWorkflowAutomation,
    updateWorkflowModalVisibility,
    editSelectedUserWorkflowRename,
    handleSelectedUserWorkflowName,
    handleDeleteSelctedActionWorkflow,
    isDeletetingSelectedUserActionWorkflow,
    isDuplicatingUserActionFlow,
  } = useAutomationDashboard();
  const {
    userActionFlowList,
    selectedUserWorkflowMode,
    selectedUserActionFlowData,
  } = useSelector((state: any) => state.workflowAutomation);
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [hoveredRow, setHoveredRow] = useState<TData | null>(null);
  const [ellipsisStyles, setEllipsisStyles] = useState({
    top: 0,
    height: 0,
  });
  const tableRef = useRef(null);

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const handleLimitChange = (newLimit: number) => {
    setLimit(newLimit);
  };

  const handlePageUpdate = (newOffset: number) => {
    setOffset(newOffset);
  };

  const handleDisableConfirmRenameSaveButton = useCallback(() => {
    const selectedUserActionFlowFilterData = userActionFlowList.filter(
      (item: IUserFlowTableDataTypes) =>
        item?.id === selectedUserActionFlowData?.id
    );
    if (
      selectedUserActionFlowFilterData &&
      selectedUserActionFlowFilterData[0]?.name ===
        selectedUserActionFlowData?.name
    ) {
      return true;
    }
    return false;
  }, [selectedUserActionFlowData, userActionFlowList]);

  const handleMouseEnter = (event: any, row: TData) => {
    setHoveredRow(row);

    // Get the position of the hovered row relative to the table
    const rowRect = event.currentTarget.getBoundingClientRect();
    //@ts-ignore
    const tableRect = tableRef?.current?.getBoundingClientRect();
    const topPosition = rowRect.top - tableRect.top;
    setEllipsisStyles({
      top: topPosition,
      height: rowRect.height - 5,
    });
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const renderRenameModal = () => {
    return (
      <Dialog
        open={
          selectedUserWorkflowMode ===
          UtilityContents.UserActionFlowTableActionLabels.RENAME
        }
        onOpenChange={() => updateWorkflowModalVisibility(null)}
      >
        <DialogContent className='bg-white sm:max-w-[425px]'>
          <DialogHeader>
            <DialogTitle>Rename Automation</DialogTitle>
            <DialogDescription className='text-textSecondary'>
              Write your automation name below.
            </DialogDescription>
          </DialogHeader>
          <div className='space-y-2'>
            <label>Name</label>
            <Input
              value={selectedUserActionFlowData?.name}
              className='w-full py-2 px-3 shadow-sm border border-gray-300 rounded-md text-sm text-gray-700 focus:ring-primary focus:border-primary focus:outline-none'
              onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                handleSelectedUserWorkflowName(value.target.value);
              }}
              placeholder='Enter automation name'
            />
          </div>
          <DialogFooter>
            <Button
              type='submit'
              className='bg-white text-black border border-solid border-border hover:bg-white hover:text-textPrimary'
              onClick={() => updateWorkflowModalVisibility(null)}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              className='bg-primary ml-3 text-white hover:bg-primary-hover'
              onClick={editSelectedUserWorkflowRename}
              disabled={handleDisableConfirmRenameSaveButton()}
            >
              Save changes
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };

  const renderDeleteModal = () => {
    return (
      <Dialog
        open={
          selectedUserWorkflowMode ===
          UtilityContents.UserActionFlowTableActionLabels.DELETE
        }
        onOpenChange={() => updateWorkflowModalVisibility(null)}
      >
        <DialogContent className='bg-white sm:max-w-[425px]'>
          <DialogHeader>
            <DialogTitle>Delete Automation?</DialogTitle>
            <DialogDescription className='text-textSecondary'>
              This will permanently delete the automation you have selected. Are
              you sure you want to delete?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              type='submit'
              className='bg-white text-black border border-solid border-border hover:bg-white hover:text-textPrimary'
              onClick={() => {
                updateWorkflowModalVisibility(null);
              }}
            >
              Cancel
            </Button>
            {isDeletetingSelectedUserActionWorkflow ? (
              <Button className='bg-destructive ml-3 w-20 text-white hover:bg-destructive-hover'>
                <LoaderCircle
                  strokeWidth={2}
                  className='text-white w-5 h-5 animate-spin'
                />
              </Button>
            ) : (
              <Button
                type='submit'
                className='bg-destructive ml-3 text-white hover:bg-destructive-hover'
                onClick={handleDeleteSelctedActionWorkflow}
              >
                Delete
              </Button>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };

  const renderEmptyView = () => {
    return (
      <div className='flex flex-col justify-center'>
        {!!searchKey ? (
          <>
            {' '}
            <img
              src={emptySearchIcon}
              alt='emptySearchIcon'
              className='h-20 mb-4'
            />
            <h3 className='mb-2 text-center text-textPrimary'>
              No Results Found
            </h3>
            <span className='subtitle-medium text-center'>
              {'Please search with different keyword.'}
            </span>
          </>
        ) : (
          <>
            {' '}
            <img
              src={emptyPageIcon}
              alt='emptySearchIcon'
              className='h-20 mb-4'
            />
            <h3 className='mb-2 text-center text-textPrimary'>
              No Automation Found
            </h3>
            <span className='subtitle-medium text-center'>
              {'Please try with different filter.'}
            </span>
          </>
        )}
      </div>
    );
  };

  return (
    <div className='flex flex-col gap-3 pb-5 bg-white'>
      <DataTableToolbar
        table={table}
        setSelectedTriggerTypes={setSelectedTriggerTypes}
        selectedTriggerTypes={selectedTriggerTypes}
        selectedSources={selectedSources}
        setSelectedSources={setSelectedSources}
        setSelectedStatus={setSelectedStatus}
        setSearchKey={setSearchKey}
      />
      <div className='relative'>
        <div ref={tableRef}>
          <DataTable
            columns={columns}
            data={data as TData[]}
            dataLoading={loading || isDuplicatingUserActionFlow}
            hasPagination={true}
            paginationProps={{
              limit: limit,
              offset: offset || 0,
              totalRowCount: createdTotalWorkflowAutomation,
              onLimitChange: handleLimitChange,
              onPageChange: handlePageUpdate,
            }}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            renderEmptyView={() => renderEmptyView()}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
          />
        </div>
        {hoveredRow !== null && (
          <div
            className='absolute right-4 mt-0.5 flex justify-center items-center z-10 bg-white'
            style={{
              top: ellipsisStyles.top,
              height: `${ellipsisStyles.height}px`,
            }}
            onMouseEnter={() => setHoveredRow(hoveredRow)} // Prevent hiding when hovering over the ellipsis
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <DataTableRowActions row={hoveredRow} />
          </div>
        )}
      </div>

      {selectedUserWorkflowMode ===
        UtilityContents.UserActionFlowTableActionLabels.DELETE &&
        renderDeleteModal()}
      {selectedUserWorkflowMode ===
        UtilityContents.UserActionFlowTableActionLabels.RENAME &&
        renderRenameModal()}
    </div>
  );
}
