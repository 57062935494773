import axios from 'axios';
import store from './stores';
import config from './config';

// HOW TO USE:
// import axios from 'axios';
// ...
// const axiosInstance = axios.create();
// const apiClient = applyInterceptor(axiosInstance); // register the interceptor with one specific axios instance
// ...
// - With custom options:
// // const apiClient = applyInterceptor(axiosInstance, {
//      shouldIntercept: (error) => {
//          return error.response.data.errorCode === 'EXPIRED_ACCESS_TOKEN';
//      }
// );

// this function defines, whether it should intercept the request or not.
const shouldIntercept = (error) => {
  try {
    return (
      error.response.status === 401 &&
      error.response.data?.code !== 'user_not_found'
    );
  } catch (e) {
    return false;
  }
};

// this function defines, how to get new access token with refresh token.\
const handleTokenRefresh = () => {
  const refreshToken = store.getState().auth.refresh;
  return new Promise((resolve, reject) => {
    axios
      .post(config.auth.refresh(), {
        refresh: refreshToken,
      })
      .then(({ data }) => {
        store.dispatch.auth.initLogin(data);
        resolve(data.access);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          store.dispatch.auth.logout();
          reject(err);
        }
      });
  });
};

// this function defines, attaching the common header including Authorization
const attachTokenToRequest = (request, token) => {
  if (token) {
    request.headers['Authorization'] = 'Token ' + token;
  }
  request.headers['Content-Type'] = 'application/json';
};

// this is the main function which handles all the intercaption logics,
// it takes a axios client and some options if you need to override anything.
const applyInterceptor = (axiosClient, customOptions = {}) => {
  let isRefreshing = false; // flag that tracks if the access token expired or not
  let failedQueue = []; // request queue, if access token expires, all other requests goes into this queue

  // defaults options
  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    shouldIntercept,
    ...customOptions,
  };

  // this functions processes all the queued requests
  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  // this function intercepts every request and attach access token to them
  const requestIterceptor = (request) => {
    const token = store.getState().auth.access;
    options.attachTokenToRequest(request, token);

    return request;
  };

  // this functions intercepts the response if the access token expires
  const responseInterceptor = (error) => {
    if (!options.shouldIntercept(error)) {
      return Promise.reject(error);
    }

    // if axios itself retrys or queued the response, we do nothing
    if (error.config._retry || error.config._queued) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (isRefreshing) {
      // pushing all the request in queue when access token expires
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest._queued = true;
          options.attachTokenToRequest(originalRequest, token);
          return axiosClient.request(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(error);
        });
    }

    // this is the default behaviour when a access token expires
    // it set the flag, then refresh the acess token, the process the call queued requests
    originalRequest._retry = true;
    isRefreshing = true;
    return new Promise((resolve, reject) => {
      options.handleTokenRefresh
        .call(options.handleTokenRefresh)
        .then((token) => {
          options.attachTokenToRequest(originalRequest, token);
          processQueue(null, token);
          resolve(axiosClient.request(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  };

  // intercepting request
  axiosClient.interceptors.request.use(requestIterceptor, (error) => {
    Promise.reject(error);
  });

  // intercepting response
  axiosClient.interceptors.response.use(undefined, responseInterceptor);

  return axiosClient;
};

const axiosInstance = axios.create();

export const AxiosStream = applyInterceptor(
  axios.create({
    responseType: 'stream',
    adapter: 'fetch',
    timeout: 60 * 1000,
  })
);

export default applyInterceptor(axiosInstance);
