import { CalendarDateRangePicker } from 'libraryV2/ui/CalendarDateRangePicker';
import {
  Button,
  React,
  useAutomationWorkflow,
  dayjs,
  useSelector,
} from '../../../export';
import { DateRange } from 'react-day-picker';
import { EditIcon, ChevronLeft, CircleHelp } from 'lucide-react';
import { Switch } from 'libraryV2/ui/switch';
import useAutomationDashboard from '../../automationDashboardV2/hooks/useAutomationDashboard';
import { navigate, useParams } from '@reach/router';
import { StoreConnectionStatus } from 'pages/automationWorkflow/interface';

interface Props {
  dateRange: DateRange;
  setDateRange: (val: DateRange) => void;
  lastUpdatedSelectedFlow: number;
  lastUsedSelectedFlow: number;
}

const AutomationHistoryHeader: React.FC<Props> = ({
  dateRange,
  setDateRange,
  lastUpdatedSelectedFlow,
  lastUsedSelectedFlow,
}) => {
  const { flowId } = useParams();
  const { selectedUserActionFlowData } = useSelector(
    (state: any) => state.workflowAutomation
  );
  const { selectedProject } = useSelector((state: any) => state.dashboard);
  const { clearStateData } = useAutomationWorkflow();
  const {
    editSelectedUserWorkflowActivation,
    isUpdateSelectedAutomationFlowLoading,
    updateSelectedUserActionFlowData,
  } = useAutomationDashboard();

  const isStoreConnected: boolean =
    selectedUserActionFlowData?.connection_status ===
    StoreConnectionStatus.CONNECTED
      ? true
      : false;

  const handleDateRangeSelect = (range: DateRange) => {
    setDateRange(range);
  };

  return (
    <div className='flex items-center justify-between w-full px-4 py-3 bg-white border-b border-gray-200 ltr:border-l rtl:border-r h-[52px]'>
      <div className='flex items-center'>
        <Button
          className='py-2 pl-2 pr-2'
          icon={<ChevronLeft className='h-5 w-5' />}
          onClick={() => {
            clearStateData();
            window.history.back();
          }}
        />
        <div className='ml-3'>
          <p className='text-textPrimary text-sm font-semibold'>
            {selectedUserActionFlowData?.name}
          </p>
          <p className='text-xs text-textSecondary'>
            Last updated at{' '}
            {lastUpdatedSelectedFlow
              ? dayjs(lastUpdatedSelectedFlow * 1000).format('MMM DD, YYYY')
              : ''}
            . Last used at{' '}
            {lastUsedSelectedFlow
              ? dayjs(lastUsedSelectedFlow * 1000).format('MMM DD, YYYY')
              : ''}
          </p>
        </div>
      </div>
      <div className='flex items-center gap-2'>
        <div className='flex items-center gap-1 px-2'>
          <a
            href='https://docs.myalice.ai/chatbot-automation/create-automation-sequences'
            target='_blank'
            rel='noopener noreferrer'
            className=''
          >
            <CircleHelp
              className='text-textSecondary cursor-pointer'
              size={15}
            />
          </a>
          <span className='text-xs'>Help</span>
        </div>
        <div>
          {isStoreConnected ? (
            <div className='flex items-center gap-1'>
              <Switch
                disabled={
                  !isStoreConnected || isUpdateSelectedAutomationFlowLoading
                }
                checked={selectedUserActionFlowData?.is_active}
                onCheckedChange={() => {
                  editSelectedUserWorkflowActivation({
                    flowId: flowId,
                    name: selectedUserActionFlowData?.name,
                    trigger_code: selectedUserActionFlowData?.trigger_code,
                    is_active: !selectedUserActionFlowData?.is_active,
                    trigger_match_unique_ref:
                      selectedUserActionFlowData?.trigger_match_unique_ref,
                  });
                  updateSelectedUserActionFlowData({
                    ...selectedUserActionFlowData,
                    is_active: !selectedUserActionFlowData?.is_active,
                  });
                }}
                className={
                  !selectedUserActionFlowData?.is_active ? 'bg-gray-300' : ''
                }
              />
              <span>
                {selectedUserActionFlowData?.is_active ? 'Active' : 'Inactive'}
              </span>
            </div>
          ) : (
            <span className='text-red-500 bg-red-50 px-2 py-1 rounded font-medium text-xs'>
              Disconnected
            </span>
          )}
        </div>

        <Button
          isDisabled={!isStoreConnected}
          onClick={() =>
            navigate(
              `/projects/${selectedProject?.id}/workflow-automation/${flowId}`
            )
          }
          className='w-fit flex gap-2'
        >
          <EditIcon size={15} />
          <span className='text-xs'>Edit Automation </span>
        </Button>
        <CalendarDateRangePicker
          dateRange={dateRange}
          onDateSelect={handleDateRangeSelect}
          variant='outline'
          className='w-full'
        />
      </div>
    </div>
  );
};

export default AutomationHistoryHeader;
