import React from 'react';
import { Button } from 'libraryV2/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'libraryV2/ui/dialog';
import { X } from 'lucide-react';

interface ToolDeleteConfirmationModalProps {
  onDeleteConfirm: () => Promise<void>;
}

export const ToolDeleteConfirmationModal: React.FC<
  ToolDeleteConfirmationModalProps
> = ({ onDeleteConfirm }) => {
  return (
    <Dialog>
      <DialogTrigger className='h-8 w-8 flex ml-auto items-center justify-center rounded-md bg-red-50'>
        <X className='w-4 h-4 text-red-500' strokeWidth={2.5} />
      </DialogTrigger>
      <DialogContent
        className='sm:max-w-[525px] bg-white'
        onClick={(e) => e.stopPropagation()}
      >
        <DialogHeader>
          <DialogTitle>Delete Website Tool?</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <div className='grid gap-4 text-textSecondary text-sm'>
          <p>
            You are about to delete the website tool. This will remove both the
            scrapped website data and all trained knowledge associated with it.
          </p>
          <p className='pt-2'>Are you sure you want to proceed?</p>
        </div>
        <DialogFooter>
          <DialogClose>
            <Button variant={'ghost'} className='bg-background-hover'>
              No
            </Button>
          </DialogClose>
          <DialogClose>
            <Button
              variant={'destructive'}
              onClick={async () => await onDeleteConfirm()}
            >
              Yes! Delete
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
