import { ICopilotConversation } from 'pages/inbox/inboxInterface';
import { FC } from 'react';
interface QuickReplayBlockProps {
  quickReply: ICopilotConversation;
}

export const QuickReplayBlock: FC<QuickReplayBlockProps> = ({ quickReply }) => {
  return (
    <div className='mb-1 inline-block border bg-zinc-100 p-2 rounded-tr-sm rounded-tl-xl rounded-br-xl rounded-bl-xl'>
      <span className='text-sm font-normal text-textPrimary'>
        {quickReply?.title}
      </span>
    </div>
  );
};
