import { Button } from 'libraryV2/ui/button';
import { FileText } from 'lucide-react';
import { FC, useEffect, useState } from 'react';
import RavenTrainButtonBg from 'pages/raven/assets/RavenTrainButtonBg.svg';
import { unionBy } from 'lodash';
import { cn } from 'libraryV2/utils';
import { ICrawledWebsitePage, WebsiteToolData } from 'pages/raven/interface';
import { useCustomAgentTools } from 'pages/raven/hooks/useCustomAgentTools';
import { useFormContext } from 'react-hook-form';
import { WebsitePageListTable } from './WebpageListTable';
import { useDispatch } from 'react-redux';
import { TrainWebsitePages } from './TrainWebsitePages';

interface ManageWebsitePageListProps {
  toolIndex: number;
  updateFormTrainedPageList: (pages: ICrawledWebsitePage[]) => void;
  crawledWebSiteData: ICrawledWebsitePage[];
}

export const ManageWebsitePageList: FC<ManageWebsitePageListProps> = ({
  toolIndex,
  crawledWebSiteData,
  updateFormTrainedPageList,
}) => {
  const dispatch = useDispatch();
  const formContext = useFormContext();

  const toolData: WebsiteToolData = formContext.getValues().tools[toolIndex];
  const [crawlingPages, setCrawlingPages] = useState<ICrawledWebsitePage[]>([]);

  const [trainedWebPageList, setTrainedWebPageList] = useState<
    ICrawledWebsitePage[]
  >(toolData?.data?.pages || []);

  const [isCrawling, setIsCrawling] = useState(false);
  const [crawlingError, setCrawlingError] = useState<string | null>(null);
  const [selectedWebPage, setSelectedWebPage] = useState<
    Record<string, boolean>
  >({});

  const { scrapeWebsiteContent, defaultAgentConfig } = useCustomAgentTools();

  const embedModelUsed =
    toolData?.data?.modelUsed || defaultAgentConfig?.gpt_embed_model?.id;

  const hasValidationError = Boolean(
    // @ts-expect-error
    formContext.formState.errors?.tools?.[toolIndex]?.data?.url
  );

  const pageListToBeTrained = unionBy(
    trainedWebPageList,
    crawledWebSiteData,
    'url'
  ).filter((page) => {
    return selectedWebPage[page?.url];
  });

  const shouldHideFetchWebsiteButton =
    hasValidationError || !toolData?.data?.url || pageListToBeTrained.length;

  const appendWebsitePage = (
    websitePage: ICrawledWebsitePage | ICrawledWebsitePage[]
  ) => {
    if (Array.isArray(websitePage)) {
      setCrawlingPages((prev) => [...prev, ...websitePage]);
      return;
    }
    setCrawlingPages((prev) => [...prev, websitePage]);
  };

  const crawlStreamResponsehandler = async (response: {
    data: ReadableStream;
  }) => {
    const stream = response.data;
    const reader = stream.pipeThrough(new TextDecoderStream()).getReader();
    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        setIsCrawling(false);
        if (!crawlingPages?.length) {
          setCrawlingError("Couldn't fetch any website pages!");
        } else {
          setCrawlingError('');
        }
        break;
      }
      if (!value || !value?.length) {
        continue;
      }
      const values = value
        .split('\n')
        .filter((v) => v?.length)
        .map((v) => JSON.parse(v));

      appendWebsitePage(values);
    }
  };

  const hanldeFetchWebsitePages = async () => {
    const payload = {
      domain: toolData?.data.url,
      embed_model_id: embedModelUsed,
    };
    try {
      setIsCrawling(true);
      scrapeWebsiteContent({
        url: payload.domain,
        embedModelId: payload.embed_model_id,
        handler: crawlStreamResponsehandler,
      });
    } catch (error) {
      setCrawlingError('Something went wrong while fetching website pages!');
      // TODO : Show error alert
      console.log('error', error);
    }
  };

  const renderFetchWebsiteButton = () => {
    return (
      <div
        className={cn('w-full flex justify-end', {
          hidden: shouldHideFetchWebsiteButton,
        })}
      >
        <Button
          type='button'
          size={'sm'}
          style={{
            backgroundImage: `url(${RavenTrainButtonBg})`,
            backgroundSize: 'cover',
          }}
          disabled={isCrawling}
          onClick={hanldeFetchWebsitePages}
          className='gap-2 min-w-[115px]'
        >
          {isCrawling ? (
            <>
              <span>Fetching Links</span>
              <div className='loading-dots-sm'></div>
            </>
          ) : (
            <>
              <FileText className='w-4 h-4 text-white' />
              <span>Fetch Links</span>
            </>
          )}
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (crawledWebSiteData?.length && !crawlingPages?.length) {
      return;
    }
    setCrawlingError('');
    dispatch.raven.updateWebsiteCrawlingData({
      url: toolData?.data?.url,
      data: crawlingPages,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crawlingPages.length]);

  useEffect(() => {
    if (!toolData?.data?.pages?.length) {
      return;
    }
    //deleted
    if (trainedWebPageList.length > toolData?.data?.pages?.length) {
      setTrainedWebPageList(toolData.data.pages);
    }
  }, [toolData?.data?.pages, trainedWebPageList.length]);

  return (
    <div className='w-full flex flex-col gap-3'>
      {renderFetchWebsiteButton()}
      <TrainWebsitePages
        trainedWebPageList={trainedWebPageList}
        setTrainedWebPageList={(pages) => {
          setTrainedWebPageList(pages);
          updateFormTrainedPageList(pages);
        }}
        toolData={toolData}
        setSelectedWebPage={setSelectedWebPage}
        pageListToBeTrained={pageListToBeTrained}
      />
      {crawlingError && !crawledWebSiteData?.length && (
        <div className='w-full text-sm font-medium leading-5 text-red-500'>
          {crawlingError}
        </div>
      )}
      <WebsitePageListTable
        crawledWebsiteList={unionBy(
          trainedWebPageList,
          crawledWebSiteData,
          'url'
        )}
        selectedWebPage={selectedWebPage}
        onSelectWebPage={setSelectedWebPage}
      />
    </div>
  );
};
