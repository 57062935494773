import { Button } from 'libraryV2/ui/button';
import { Sparkles } from 'lucide-react';
import { ICrawledWebsitePage, WebsiteToolData } from 'pages/raven/interface';
import RavenTrainButtonBg from 'pages/raven/assets/RavenTrainButtonBg.svg';
import { FC, useEffect, useState } from 'react';
import { useCustomAgentTools } from 'pages/raven/hooks/useCustomAgentTools';
import { unionBy } from 'lodash';

interface TrainWebsitePagesProps {
  pageListToBeTrained: ICrawledWebsitePage[];
  toolData: WebsiteToolData;
  trainedWebPageList: ICrawledWebsitePage[];
  setSelectedWebPage: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  setTrainedWebPageList: (pages: ICrawledWebsitePage[]) => void;
}

export const TrainWebsitePages: FC<TrainWebsitePagesProps> = ({
  pageListToBeTrained,
  toolData,
  trainedWebPageList,
  setSelectedWebPage,
  setTrainedWebPageList,
}) => {
  const [isBulkTraining, setIsBulkTraining] = useState(false);
  const [newTrainedWebPageList, setNewTrainedWebPageList] = useState<
    ICrawledWebsitePage[]
  >([]);
  const { bulkTrainBWebPage } = useCustomAgentTools();

  const appendWebsitePage = (
    websitePage: ICrawledWebsitePage | ICrawledWebsitePage[]
  ) => {
    if (Array.isArray(websitePage)) {
      setNewTrainedWebPageList((prev) => {
        const finalTrainedWebPageList = unionBy(prev, websitePage, 'url');
        return finalTrainedWebPageList;
      });
      return;
    } else {
      setNewTrainedWebPageList((prev) => {
        return [...prev, websitePage];
      });
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const bulkTrainStreamResponsehandler = async (response: {
    data: ReadableStream;
  }) => {
    const stream = response.data;
    const reader = stream.pipeThrough(new TextDecoderStream()).getReader();
    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        setIsBulkTraining(false);
        break;
      }
      if (!value || !value?.length) {
        continue;
      }
      // parse the stream response
      const newTrainedWebPages = value
        .split('\n')
        .filter((v) => v?.length)
        .map((v) => JSON.parse(v))
        .map((v) => {
          return {
            ...v,
            estimated_token_count: v?.token_usage,
            tool_id: toolData?.id,
          };
        });
      appendWebsitePage(newTrainedWebPages);
    }
  };

  const handleTrainSelectedWebPage = async () => {
    setIsBulkTraining(true);
    const selectedWebPageList = pageListToBeTrained;
    bulkTrainBWebPage({
      webPages: selectedWebPageList,
      tool_id: toolData?.id,
      handler: bulkTrainStreamResponsehandler,
    });
  };

  useEffect(() => {
    if (!newTrainedWebPageList.length) {
      return;
    }

    if (!isBulkTraining) {
      const finalTrainedWebPageList = unionBy(
        trainedWebPageList,
        newTrainedWebPageList,
        'url'
      );
      setTrainedWebPageList(finalTrainedWebPageList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTrainedWebPageList, isBulkTraining]);

  if (!pageListToBeTrained.length) {
    return null;
  }

  return (
    <div className='w-full flex justify-between items-center'>
      <p className='text-sm font-medium leading-5 text-textPrimary'>
        {pageListToBeTrained.length} items selected
      </p>
      <Button
        type='button'
        size={'sm'}
        style={{
          backgroundImage: `url(${RavenTrainButtonBg})`,
          backgroundSize: 'cover',
        }}
        disabled={isBulkTraining}
        className='gap-1 min-w-[78px]'
        onClick={handleTrainSelectedWebPage}
      >
        {isBulkTraining ? (
          <div className='loading-dots'></div>
        ) : (
          <>
            <Sparkles className='w-4 h-4 text-white' />
            <span>Train</span>
          </>
        )}
      </Button>
    </div>
  );
};
