'use client';
import useTranslation from 'components/customHooks/useTranslation';

import { FC, ReactNode, useState } from 'react';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'libraryV2/ui/tabs';
import dayjs from 'dayjs';
import MetricsGraphLoader from './MetricsGraphLoader';
import { useSelector } from 'react-redux';
import notFoundImage from '../../../../../assets/icons/emptyPageIcon.svg';
import { IStatusTimeLine } from 'pages/automationWorkflow/interface';

interface MetricesGraphProps {
  loading: boolean;
  timeLines: IStatusTimeLine[];
}
interface IGraphData {
  date: string;
  automation_triggered: number;
  automation_succeeded: number;
  automation_failed: number;
  total_recovered_carts: number;
  total_sales: number;
  conversion_rate: number;
}

interface CustomLineChartProps {
  data: IGraphData[];
  children: ReactNode;
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (!active || !payload || payload.length === 0) {
    return null;
  }

  return (
    <div className='bg-black border border-gray-300 rounded-md p-3 shadow-md'>
      <p className='text-xs text-white mb-1'>
        {dayjs(label).format('MMM DD, YYYY')}
      </p>
      {payload.map((entry: any, index: number) => (
        <p key={index} className='text-xs text-white flex items-center gap-2'>
          {entry.name} - {''}
          {entry.value.toLocaleString('en-US', { maximumFractionDigits: 2 })}
        </p>
      ))}
    </div>
  );
};

const CustomLineChart: FC<CustomLineChartProps> = ({ data, children }) => {
  return (
    <div className='border rounded py-5 pe-4 w-full bg-white shadow-sm'>
      <ResponsiveContainer width={'100%'} height={250}>
        <LineChart data={data}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey='date'
            tickLine={false}
            padding={{ left: 20, right: 20 }}
            hide={false}
            tickFormatter={(value, index) => {
              if (index === 0 || index === data.length - 1) {
                return dayjs(value).format('MMM DD');
              }
              return '';
            }}
            tickMargin={10}
          />
          <YAxis axisLine={false} tickLine={false} />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            align='center'
            iconType='square'
            wrapperStyle={{ paddingTop: 10 }}
          />
          {children}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export const MetricesGraph: FC<MetricesGraphProps> = ({
  loading,
  timeLines,
}) => {
  const { t } = useTranslation();
  const { selectedUserActionFlowData } = useSelector(
    (state: any) => state.workflowAutomation
  );
  const [selectedTab, setSelectedTab] = useState('automation_trends');
  const getSanitizedTimeLines = () => {
    const hasValueGreaterThanZiro = timeLines?.some((obj) =>
      Object.entries(obj).some(([key, value]) => key !== 'date' && value > 0)
    );
    return hasValueGreaterThanZiro ? timeLines : [];
  };
  const data = getSanitizedTimeLines() || [];

  const renderEmptyGraph = () => {
    return (
      <div className='w-full h-72 border rounded flex flex-col items-center justify-center'>
        <div className='flex flex-col items-center text-center'>
          <img
            src={notFoundImage}
            alt='emptySearchIcon'
            className='h-24 mb-4'
          />
          <p className='text-lg font-semibold'>No Reports Found</p>
          <p className='text-sm text-textSecondary'>
            There is no reports available for the selected time range.
          </p>
        </div>
      </div>
    );
  };

  const renderTrendsGraph = () => {
    return (
      <>
        {data.length <= 0 ? (
          renderEmptyGraph()
        ) : (
          <CustomLineChart data={data}>
            <Line
              type='linear'
              dataKey={t('automation_triggered')}
              stroke='#A78BFA'
              dot={false}
              activeDot={{ r: 8 }}
              name='Automation Triggered'
            />
            <Line
              type='linear'
              dataKey={t('automation_succeeded')}
              stroke='#22C55E'
              dot={false}
              activeDot={{ r: 8 }}
              name='Automation Succeeded'
            />
            <Line
              type='linear'
              dataKey={t('automation_failed')}
              stroke='#FF2323'
              dot={false}
              activeDot={{ r: 8 }}
              name='Automation Failed'
            />
          </CustomLineChart>
        )}
      </>
    );
  };

  const renderTotalRecoveredCartsGraph = () => {
    return (
      <>
        {data.length <= 0 ? (
          renderEmptyGraph()
        ) : (
          <CustomLineChart data={data}>
            <Line
              type='linear'
              dataKey={t('total_recovered_carts')}
              stroke='#22C55E'
              dot={false}
              activeDot={{ r: 8 }}
              name='Total Recovered Carts'
            />
          </CustomLineChart>
        )}
      </>
    );
  };

  const renderTotalSalesGraph = () => {
    return (
      <>
        {data.length <= 0 ? (
          renderEmptyGraph()
        ) : (
          <CustomLineChart data={data}>
            <Line
              type='linear'
              dataKey={t('total_sales')}
              stroke='#22C55E'
              dot={false}
              activeDot={{ r: 8 }}
              name='Total Sales'
            />
          </CustomLineChart>
        )}
      </>
    );
  };

  const renderConversionRateGraph = () => {
    return (
      <>
        {data.length <= 0 ? (
          renderEmptyGraph()
        ) : (
          <CustomLineChart data={data}>
            <Line
              type='linear'
              dataKey={t('conversion_rate')}
              stroke='#22C55E'
              dot={false}
              activeDot={{ r: 8 }}
              name='Conversion Rate'
            />
          </CustomLineChart>
        )}
      </>
    );
  };

  return (
    <div>
      <p className='text-base font-semibold text-textPrimary'>Trends</p>
      {selectedUserActionFlowData?.trigger_name !== 'Cart Abandoned' && (
        <p className='text-sm text-textSecondary pl-1 pb-2'>
          Analyze changes in automation results for a specific time period.
        </p>
      )}
      {loading ? (
        <MetricsGraphLoader />
      ) : selectedUserActionFlowData?.trigger_name === 'Cart Abandoned' ? (
        <Tabs defaultValue='automation_trends'>
          <div className='flex items-center justify-between'>
            <p className='text-sm text-textSecondary pl-1'>
              Analyze changes in automation results for a specific time period.
            </p>
            <TabsList className='bg-zinc-100'>
              <TabsTrigger
                value='automation_trends'
                className={
                  selectedTab === 'automation_trends'
                    ? 'bg-white text-xs'
                    : 'text-xs'
                }
                onClick={() => setSelectedTab('automation_trends')}
              >
                Automation Trends
              </TabsTrigger>
              <TabsTrigger
                value='total_recovered_carts'
                className={
                  selectedTab === 'total_recovered_carts'
                    ? 'bg-white text-xs'
                    : 'text-xs'
                }
                onClick={() => setSelectedTab('total_recovered_carts')}
              >
                Total Recovered Carts
              </TabsTrigger>
              <TabsTrigger
                value='total_sales'
                className={
                  selectedTab === 'total_sales' ? 'bg-white text-xs' : 'text-xs'
                }
                onClick={() => setSelectedTab('total_sales')}
              >
                Total Sales
              </TabsTrigger>
              <TabsTrigger
                value='conversion_rate'
                className={
                  selectedTab === 'conversion_rate'
                    ? 'bg-white text-xs'
                    : 'text-xs'
                }
                onClick={() => setSelectedTab('conversion_rate')}
              >
                Conversion Rate
              </TabsTrigger>
            </TabsList>
          </div>

          <TabsContent value='automation_trends'>
            {renderTrendsGraph()}
          </TabsContent>
          <TabsContent value='total_recovered_carts'>
            {renderTotalRecoveredCartsGraph()}
          </TabsContent>
          <TabsContent value='total_sales'>
            {renderTotalSalesGraph()}
          </TabsContent>
          <TabsContent value='conversion_rate'>
            {renderConversionRateGraph()}
          </TabsContent>
        </Tabs>
      ) : (
        renderTrendsGraph()
      )}
    </div>
  );
};
