'use client';
import { ColumnDef } from '@tanstack/react-table';
// import { Checkbox } from 'libraryV2/ui/checkbox';
// import { cn } from 'libraryV2/utils';
import { startCase } from 'lodash';
import { truncateText } from 'utilities/utils';
import { dayjs } from '../../../../../export';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { SwitchComponent } from './SwitchComponent';
import useAutomationDashboard from '../../../hooks/useAutomationDashboard';
dayjs.extend(LocalizedFormat);

interface IWorkflowColumnData {
  id: string;
  name: string;
  trigger_match_unique_ref: number;
  ecommerce_tag_code: string;
  trigger_name: string;
  orders: number;
  sales: number;
  is_active: boolean;
  automation_succeeded: number;
  automation_failed: number;
}

interface SalesDataProps {
  sales: number | null;
}

export const SalesComponent: React.FC<SalesDataProps> = ({ sales }) => {
  const { selectedProject } = useAutomationDashboard();

  return (
    <div className='text-zinc-900 text-sm'>
      <span>
        {sales
          ? `${selectedProject?.currency_code} ${sales?.toLocaleString('en', {
              maximumFractionDigits: 2,
            })}`
          : '--'}
      </span>
    </div>
  );
};

export const columns: ColumnDef<IWorkflowColumnData>[] = [
  // will need while bulk delete api is ready.
  // {
  //   id: 'select',
  //   header: ({ table }) => (
  //     <Checkbox
  //       checked={
  //         table.getIsAllPageRowsSelected() ||
  //         (table.getIsSomePageRowsSelected() && 'indeterminate')
  //       }
  //       onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
  //       aria-label='Select all'
  //       className={cn(
  //         'translate-y-[2px]',
  //         (table.getIsSomePageRowsSelected() ||
  //           table.getIsAllPageRowsSelected()) &&
  //           'bg-primary border-primary text-white'
  //       )}
  //       IsSomePageRowsSelected={
  //         table.getIsSomePageRowsSelected() || table.getIsAllPageRowsSelected()
  //       }
  //     />
  //   ),
  //   cell: ({ row }) => (
  //     <Checkbox
  //       checked={row.getIsSelected()}
  //       onCheckedChange={(value) => row.toggleSelected(!!value)}
  //       aria-label='Select row'
  //       className={cn(
  //         'translate-y-[2px] data-[state=checked]:bg-green-500',
  //         row.getIsSelected() && 'bg-primary border-primary text-white'
  //       )}
  //     />
  //   ),
  // },

  {
    accessorKey: 'name',
    header: () => <div className='text-sm text-zinc-500'>Automation Name</div>,
    cell: ({ row }) => {
      const title: string = row.getValue('name') ?? '';
      return (
        <div className='text-sm text-zinc-900'>{truncateText(title, 30)}</div>
      );
    },
  },
  {
    accessorKey: 'created_at',
    header: () => <div className='text-sm text-zinc-500'>Created At</div>,
    cell: ({ row }) => {
      return (
        <div className=' text-zinc-900 text-sm'>
          {dayjs.unix(Number(row.getValue('created_at'))).format('MMM D, YYYY')}
        </div>
      );
    },
  },
  {
    accessorKey: 'ecommerce_tag_code',
    header: () => <div className='text-sm text-zinc-500'>Sources</div>,
    cell: ({ row }) => {
      const title: string = startCase(row.getValue('ecommerce_tag_code')) ?? '';
      return (
        <div className=' text-zinc-900 text-sm'>
          <span>{title}</span>
        </div>
      );
    },
  },
  {
    accessorKey: 'trigger_name',
    header: () => <div className='text-sm text-zinc-500'>Trigger Type</div>,
    cell: ({ row }) => {
      const title: string = row.getValue('trigger_name') ?? '';
      const triggerColors: { [key: string]: string } = {
        'Order Created': 'text-indigo-500',
        'Cart Abandoned': 'text-amber-500',
        'Order Fulfilled': 'text-green-500',
        'Order Cancelled': 'text-red-600',
        'Order Status Updated': 'text-blue-500',
      };

      const bgColors: { [key: string]: string } = {
        'Order Created': 'bg-indigo-500',
        'Cart Abandoned': 'bg-amber-500',
        'Order Fulfilled': 'bg-green-500',
        'Order Cancelled': 'bg-red-600',
        'Order Status Updated': 'bg-blue-500',
      };

      const textColor = triggerColors[title];
      const bgColor = bgColors[title];

      return (
        <div
          className={`inline-flex items-center border rounded px-2 gap-1 ${textColor}`}
        >
          <div className={`w-2 h-2 ${bgColor} rounded-full`}></div>
          <span className='text-sm'>{title}</span>
        </div>
      );
    },
  },
  {
    id: 'succeed_failed',
    header: () => <div className='text-sm text-zinc-500'>Success/Failed</div>,
    cell: ({ row }) => {
      const { automation_succeeded, automation_failed } = row.original;

      return (
        <div className='text-zinc-900 text-sm'>
          <span>
            <span>{automation_succeeded ? automation_succeeded : '--'}</span> /{' '}
            <span>{automation_failed ? automation_failed : '--'}</span>
          </span>
        </div>
      );
    },
  },

  {
    accessorKey: 'orders',
    header: () => <div className='text-sm text-zinc-500'>Orders</div>,
    cell: ({ row }) => {
      const title: number = row.getValue('orders') ?? '';
      return (
        <div className='text-zinc-900 text-sm'>
          <span>{title ? title : '--'}</span>
        </div>
      );
    },
  },
  {
    accessorKey: 'sales',
    header: () => <div className='text-sm text-zinc-500'>Sales</div>,
    cell: ({ row }) => {
      const title: number = row.getValue('sales') ?? '';
      return <SalesComponent sales={title} />;
    },
  },

  {
    accessorKey: 'is_active',
    header: () => <div className='text-sm text-zinc-500'>Status</div>,
    cell: ({ row }) => <SwitchComponent row={row} />,
  },
];
