import { FC } from 'react';
import * as React from 'react';
import { ICrawledWebsitePage } from 'pages/raven/interface';
import { cn } from 'libraryV2/utils';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Checkbox } from 'libraryV2/ui/checkbox';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'libraryV2/ui/table';
import { Gem } from 'lucide-react';
import { DeleteTrainedPageModal } from './DeleteTrainedPageModal';

interface WebpageListTableProps {
  crawledWebsiteList: ICrawledWebsitePage[];
  selectedWebPage: Record<number, boolean>;
  onSelectWebPage: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
}

export const columns: ColumnDef<ICrawledWebsitePage>[] = [
  {
    id: 'select',
    header: ({ table }) => {
      const isRowSelected =
        table.getIsAllPageRowsSelected() ||
        (table.getIsSomePageRowsSelected() && 'indeterminate');
      return (
        <Checkbox
          checked={isRowSelected}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label='Select all'
          className={cn({
            'bg-primary translate-y-[2px] text-white ring-0 border-0':
              isRowSelected,
          })}
          IsSomePageRowsSelected={!!isRowSelected}
        />
      );
    },
    cell: ({ row }) => {
      const isPageTrained = !!row.original?.id;
      return (
        <Checkbox
          disabled={isPageTrained}
          checked={isPageTrained || row.getIsSelected()}
          aria-label='Select row'
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          className={cn({
            'bg-primary text-white ring-0 border-0':
              isPageTrained || row.getIsSelected(),
          })}
        />
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'title',
    header: 'Pages',
    cell: ({ row }) => (
      <div className='capitalize'>{row.getValue('title')}</div>
    ),
  },
  {
    id: 'estimated_token_count',
    header: () => <span className='bg-white hidden'>Token Required</span>,
    cell: ({ row }) => {
      return (
        <div className='flex items-center min-w-[80px] gap-1 relative'>
          <div className='hidden flex-shrink-0 gap-1 items-center w-fit border border-border px-1.5 py-0.5 rounded-md'>
            <Gem strokeWidth={2.5} className='text-purple-500 w-3 h-3' />
            <span className='text-xs font-semibold text-purple-500'>
              {row.original?.estimated_token_count}
            </span>
          </div>
          <div
            className={cn(
              'bg-background-hover py-0.5 px-1.5 rounded w-fit font-medium text-xs',
              {
                hidden: !row.original?.id,
              }
            )}
          >
            Trained
          </div>
          <div
            className={cn(
              'hidden group-hover:block group-hover:absolute group-hover:right-2',
              {
                'group-hover:hidden': !row.original?.id,
              }
            )}
          >
            <DeleteTrainedPageModal toolData={row.original} />
          </div>
        </div>
      );
    },
  },
];

export const WebsitePageListTable: FC<WebpageListTableProps> = ({
  crawledWebsiteList,
  selectedWebPage,
  onSelectWebPage,
}) => {
  const table = useReactTable({
    data: crawledWebsiteList,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: onSelectWebPage,
    getRowId: (row) => row.url,
    state: {
      rowSelection: selectedWebPage,
    },
  });

  if (!Array.isArray(crawledWebsiteList) || crawledWebsiteList.length === 0) {
    return null;
  }

  return (
    <div className=' w-full rounded-md border'>
      <Table divClass='max-h-[320px] rounded-md relative'>
        <TableHeader className={'sticky top-0 z-20 bg-white'}>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                className='hover:bg-background-hover group'
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className='h-24 text-center'>
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
