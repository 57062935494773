import { DataTable } from 'libraryV2/ui/data-table';
import { FC, useEffect, useRef, useState } from 'react';
import { automationHistoryTableColumns } from './components/Columns';
import { useSelector } from 'react-redux';
import useAutomationHistories from '../../hooks/useAutomationHistories';
import { DataTableToolbar } from './components/DataTableToolbar';
import { DateRange } from 'react-day-picker';
import dayjs from 'dayjs';
import emptyPageIcon from 'assets/icons/emptyPageIcon.svg';
import { useParams } from '@reach/router';
import { DataTableRowActions } from './components/DataTableRowActions';
import { IUserFlowLogTableDataTypes } from 'pages/automationWorkflow/interface';

interface HistoryTableContainerProps {
  dateRange: DateRange;
  setLastUsedSelectedFlow: (val: number) => void;
  setLastUpdatedSelectedFlow: (val: number) => void;
}

export const AutomationHistoryTableContainer: FC<
  HistoryTableContainerProps
> = ({ dateRange, setLastUsedSelectedFlow, setLastUpdatedSelectedFlow }) => {
  const { flowId } = useParams();
  const { selectedUserActionFlowData, selectedAutomationLogList } = useSelector(
    (state: any) => state.workflowAutomation
  );
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState<number>(0);
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [selectedRecoveredStatus, setSelectedRecoveredStatus] = useState<
    boolean | undefined
  >(undefined);
  const [searchKey, setSearchKey] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [hoveredRow, setHoveredRow] =
    useState<IUserFlowLogTableDataTypes | null>(null);
  const [ellipsisStyles, setEllipsisStyles] = useState({
    top: 0,
    height: 0,
  });
  const tableRef = useRef(null);

  const { fetchSelectedUserActionFlowLogData } = useAutomationHistories();

  useEffect(() => {
    if (!firstRender) {
      setOffset(-1);
    }
    // eslint-disable-next-line
  }, [selectedStatus, selectedRecoveredStatus, searchKey, dateRange, limit]);

  useEffect(() => {
    if (offset < 0) {
      setOffset(0);
    } else {
      setLoading(true);
      fetchSelectedUserActionFlowLogData({
        user_action_flow_id: flowId,
        offset: offset > 0 ? offset : 0,
        limit: limit,
        start_datetime: dayjs(dateRange.from).toISOString(),
        end_datetime: dayjs(dateRange.to).toISOString(),
        recovered_status: selectedRecoveredStatus,
        status: selectedStatus ? selectedStatus : undefined,
        unique_identifier_value: searchKey,
      })
        .then((res) => {
          if (res?.data?.success === true) {
            setLastUsedSelectedFlow(res?.data?.dataSource?.flow_last_used_at);
            setLastUpdatedSelectedFlow(
              res?.data?.dataSource?.flow_last_updated_at
            );
            setTotalRowCount(res?.data?.dataSource?.total);
          }
          setLoading(false);
          setFirstRender(false);
        })
        .catch(() => {
          setLoading(false);
          setFirstRender(false);
        });
    }

    // eslint-disable-next-line
  }, [offset]);

  const filteredColumns =
    selectedUserActionFlowData?.trigger_name === 'Cart Abandoned'
      ? automationHistoryTableColumns?.filter(
          (item) => item.header !== 'Order ID'
        )
      : automationHistoryTableColumns?.filter(
          (item) =>
            item.header !== 'Checkout ID' &&
            item.header !== 'Recovered Status' &&
            item.header !== 'Sales'
        );

  const handleLimitChange = (newLimit: number) => {
    setLimit(newLimit);
  };

  const handlePageUpdate = (newOffset: number) => {
    setOffset(newOffset);
  };

  const renderTableLoader = () => (
    <div>
      {Array.from({ length: 10 }).map((_, index) => (
        <div
          key={index}
          className='w-full py-3.5 mx-auto border-b border-gray-100'
        >
          <div className='flex items-center space-x-2 animate-pulse'>
            {Array.from({ length: 6 }).map((_, subIndex) => (
              <div key={subIndex} className='w-full h-5 bg-gray-200 rounded' />
            ))}
          </div>
        </div>
      ))}
    </div>
  );

  const renderEmptyView = () => (
    <div className='flex flex-col justify-center'>
      <img src={emptyPageIcon} alt='emptySearchIcon' className='h-20 mb-4' />
      <h3 className='mb-2 text-center text-textPrimary'>No History Found</h3>
      <span className='subtitle-medium text-center'>
        There is no history available for the selected time range.
      </span>
    </div>
  );
  const handleMouseEnter = (event: any, row: any) => {
    setHoveredRow(row?.original);

    // Get the position of the hovered row relative to the table
    const rowRect = event.currentTarget.getBoundingClientRect();
    //@ts-ignore
    const tableRect = tableRef?.current?.getBoundingClientRect();
    const topPosition = rowRect.top - tableRect.top;
    setEllipsisStyles({
      top: topPosition,
      height: rowRect.height - 5,
    });
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  return (
    <div className='w-full flex flex-col gap-3 pb-10'>
      <p className='text-base font-semibold text-textPrimary'>
        Automations Run History
      </p>
      <p className='text-sm text-textSecondary'>
        Track automation history for the selected time range.
      </p>
      <DataTableToolbar
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        setSelectedRecoveredStatus={setSelectedRecoveredStatus}
        setSearchKey={setSearchKey}
      />
      <div className='relative'>
        <div className='bg-white shadow-sm' ref={tableRef}>
          <DataTable
            columns={filteredColumns}
            data={selectedAutomationLogList}
            hasPagination={true}
            dataLoading={loading}
            paginationProps={{
              limit: limit,
              offset: offset,
              totalRowCount: totalRowCount,
              onLimitChange: handleLimitChange,
              onPageChange: handlePageUpdate,
            }}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            renderEmptyView={() => renderEmptyView()}
            renderLoadingView={() => renderTableLoader()}
          />
        </div>
        {hoveredRow !== null && (
          <div
            className='absolute right-2 mt-0.5 flex justify-center items-center z-10 bg-white'
            style={{
              top: ellipsisStyles.top,
              height: `${ellipsisStyles.height}px`,
            }}
            onMouseEnter={() => setHoveredRow(hoveredRow)} // Prevent hiding when hovering over the ellipsis
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <DataTableRowActions history_id={hoveredRow?.history_id} />
          </div>
        )}
      </div>
    </div>
  );
};
