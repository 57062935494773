import { format } from 'date-fns';
import { unionBy } from 'lodash';
import {
  AgentToolsApiResponse,
  CustomAgentDefaultConfig,
  CustomAgentToolType,
  ICrawledWebsitePage,
  IManageGPTAgent,
  WebsiteToolApiResponse,
  TCreateCustomAgentFormData,
  TestMessageType,
  WebsiteToolData,
} from '../interface';
import { dummyDocumentdata } from '../components/createAgents/steps/tools/toolsSchema';
type Role = 'user' | 'assistant';

export const formatTestMessageData = (data: TestMessageType) => {
  const id = data?.id || (Math.random() * Math.random()).toString(30).slice(2);
  const content = data?.content || data?.message?.content || '';
  const source = data?.role || data?.message?.role;
  const time = data?.timestamp
    ? // @ts-expect-error
      format(data?.timestamp, 'hh:mm a')
    : format(new Date(), 'hh:mm a');
  const systemUpdates = data?.reasonings || [];

  const result = {
    id,
    content,
    source: source as Role,
    time,
    systemUpdates,
  };

  return result;
};

const mapWebsiteToolApiResponseToState = (
  websiteApiData: WebsiteToolApiResponse
): WebsiteToolData => {
  const pages: ICrawledWebsitePage[] = websiteApiData?.points?.map((point) => {
    return {
      id: point?.id,
      title: point?.payload?.title,
      estimated_token_count: point?.payload?.metadata.token_usage,
      url: point?.payload?.url,
      tool_id: websiteApiData.id,
    };
  });

  return {
    id: websiteApiData.id,
    type: 'website',
    isOpen: false,
    data: {
      title: websiteApiData?.name || '',
      modelUsed: websiteApiData?.embed_model?.id || '',
      description: websiteApiData?.description || '',
      url: websiteApiData?.base_url || '',
      pages: pages || [],
    },
  };
};

export const mapApiResponseToState = (
  apiData: IManageGPTAgent
): TCreateCustomAgentFormData => {
  return {
    id: apiData?.id || '',
    status: apiData?.status || 'draft',
    name: apiData?.title || '',
    public_description: apiData?.description || '',
    backstory: apiData?.instruction,
    role: apiData?.role || '',
    model_used: apiData?.gptmodel_id,
    memory_limit: apiData?.memory_limit || 5,
    temperature: apiData?.temperature || 0.0,
    top_p: apiData?.top_p || 0.0,
    tools: [],
  };
};

export const mapToolsApiResponseToState = (
  toolsApiData: AgentToolsApiResponse
) => {
  const finalToolsData: WebsiteToolData[] = [];

  toolsApiData.forEach((tool) => {
    if (tool?.type === 'website') {
      finalToolsData.push(mapWebsiteToolApiResponseToState(tool));
    }
  });
  return finalToolsData;
};

export const hasValidToolData = (agentData: TCreateCustomAgentFormData) => {
  for (let i = 0; i < (agentData?.tools?.length || 0); i++) {
    const toolData = agentData.tools?.at(i) as WebsiteToolData;
    if (toolData?.type === 'website') {
      if (
        toolData?.data?.title &&
        toolData?.data?.description &&
        toolData?.data?.pages?.length > 0
      ) {
        continue;
      } else {
        return false;
      }
    }
  }
  return true;
};

export const calculateCompletedStepsFromAgentData = (
  agentData: TCreateCustomAgentFormData
): number => {
  if (agentData?.status !== 'draft') {
    return 5;
  }
  if (hasValidToolData(agentData)) {
    return 4;
  }
  if (agentData?.backstory && agentData?.role) {
    return 2;
  }
  if (agentData?.name && agentData?.public_description) {
    return 1;
  }
  return 0;
};

export const getDefaultToolData = ({
  toolType,
  toolId,
  defaultConfig,
}: {
  toolType: CustomAgentToolType;
  toolId: string;
  defaultConfig: CustomAgentDefaultConfig;
}) => {
  const baseData = {
    id: toolId?.length
      ? toolId
      : (Date.now() * Math.random()).toString(30).slice(2),
    type: toolType,
    isOpen: true,
  };

  switch (toolType) {
    case 'api':
      return {
        ...baseData,
        data: {
          method: 'GET',
          url: '',
          headers: [{ key: '', value: '' }],
          requestData: '',
        },
      };
    case 'document':
      return {
        ...baseData,
        data: {
          title: '',
          modelUsed: '',
          description: '',
          documents: dummyDocumentdata,
        },
      };
    case 'website':
      return {
        ...baseData,
        data: {
          title: '',
          modelUsed: defaultConfig?.gpt_embed_model?.id || '',
          description: '',
          url: '',
          pages: [],
        },
      };
  }
};

export const getUniqueWebsitePageListByUrl = (
  formPageList: ICrawledWebsitePage[],
  newPageList: ICrawledWebsitePage[]
) => {
  const result = unionBy(formPageList, newPageList, 'url');
  return result;
};
